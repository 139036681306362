// Copy the comment below into your developer console to see the JavaScript logs from the application.
// localStorage.setItem('logLevel', JSON.stringify(['all'])); - To get all messages. Select from options below.
// localStorage.setItem('logLevel', JSON.stringify(['all', 'log', 'debug', 'warn', 'error']));

const logLevel = localStorage.getItem('logLevel') ? JSON.parse(localStorage.getItem('logLevel')) : [];

const log = {
  style: 'color: #FFFFFF; padding: 2px 5px;',
  info: (msg, ...args) => (logLevel.includes('log') || logLevel.includes('all')) && console.log(`%c${msg}`, `background: #5DADE2; ${log.style}`, ...args),
  warn: (msg, ...args) => console.warn(`%c${msg}`, `background: #FF8C00; ${log.style}`, ...args),
  error: (msg, ...args) => console.error(`%c${msg}`, `background: #FF0000; ${log.style}`, ...args),
  debug: (...args) => (logLevel.includes('debug') || logLevel.includes('all')) && console.log(...args),
};

const likeEmoji = String.fromCodePoint(0x1F44D);
log.info('Info level logging enabled!', likeEmoji);
log.debug('Debug level logging enabled!', likeEmoji);

export default log;
