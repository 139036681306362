import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['info', 'noAuto'];

  initialize() {
    this.index = 0;
    this.items = 3;
  }

  connect() {
    this.startCarousel();
  }

  disconnect() {
    this.stopCarousel();
  }

  startCarousel() {
    if (this.noAutoTargets.length === 0) {
      this.showNextDiv();
      this.interval = setInterval(() => {
        this.showNextDiv();
      }, 6000);
    }
  }

  stopCarousel() {
    clearInterval(this.interval);
  }

  next() {
    this.showNextDiv();
  }

  show(event) {
    this.stopCarousel();
    this.infoTargets.forEach((element, index) => {
      const svg = document.getElementById(`icon${index}`);
      let paths = null;
      if (svg) {
        paths = svg.querySelectorAll('path');
      }
      if (index.toString() === event.params.id.toString()) {
        element.classList.remove('d-none');
        if (paths) {
          paths.forEach((path) => {
            path.style.stroke = '#2da3dc';
          });
        }
      } else {
        element.classList.add('d-none');
        if (paths) {
          paths.forEach((path) => {
            path.style.stroke = '#b9e5fb';
          });
        }
      }
    });
  }

  hide() {
    this.infoTargets.forEach((element, index) => {
      element.classList.add('d-none');
    });
    this.startCarousel();
  }

  showNextDiv() {
    this.index = (this.index + 1) % this.infoTargets.length;
    this.updateVisibleDiv();
  }

  updateVisibleDiv() {
    this.infoTargets.forEach((element, index) => {
      const svg = document.getElementById(`icon${index}`);
      let paths = null;
      if (svg) {
        paths = svg.querySelectorAll('path');
      }
      if (index === this.index) {
        element.classList.remove('d-none');
        if (paths) {
          paths.forEach((path) => {
            path.style.stroke = '#2da3dc';
          });
        }
      } else {
        element.classList.add('d-none');
        if (paths) {
          paths.forEach((path) => {
            path.style.stroke = '#b9e5fb';
          });
        }
      }
    });
  }
}
