import consumer from './consumer';
import { EventBus } from '../visual-schedules/event-bus';

export default class VisualScheduleChannel {
  constructor(options = {}) {
    this.sessionId = options.sessionId; // Either a room_session ID or one generated that
    this.store = options.store; // Vuex store
    this.channel = null;
  }

  init = async () => {
    await this.createSubscription();
  }

  createSubscription = () => {
    const {
      sessionId, store,
    } = this;

    this.channel = consumer.subscriptions.create({
      channel: 'VisualScheduleChannel',
      visual_schedule: `visual_schedule_${sessionId}`,
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        if (this.channel) this.channel.unsubscribe();
      },

      received(data) {
        // Do nothing if it's the schedule owner
        if (store.state.visitor.id === data.id) return;

        if (data.type === 'update_visual_schedule') {
          const visualSchedule = { ...store.state.visualSchedule };
          data.visualSchedule.staticRevealer = visualSchedule.staticRevealer;
          store.commit('setVisualSchedule', data.visualSchedule);
        } else if (data.type === 'update_image_revealer') {
          const visualSchedule = { ...store.state.visualSchedule };
          visualSchedule.staticRevealer = data.staticRevealer;
          store.commit('setVisualSchedule', visualSchedule);
        } else if (data.type === 'timer_start') {
          EventBus.$emit('timer-state', true);
        } else if (data.type === 'paused') {
          EventBus.$emit('timer-state', false);
        } else if (data.type === 'timer_reset') {
          EventBus.$emit('timer-reset');
        } else if (data.type === 'timer_duration') {
          EventBus.$emit('timer-duration', data.value);
        }
      },
    });
  }
}
