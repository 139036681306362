// Mobile Search Functionality

document.addEventListener('turbolinks:load', (event) => {
  const mobileSearchActivationBtn = document.getElementById('mobileSearchBtn');
  const mobileSearchBackBtn = document.getElementById('mobileSearchReturnBtn');

  if (mobileSearchActivationBtn && mobileSearchBackBtn) {
    const nav = document.querySelector('nav.navbar');
    mobileSearchActivationBtn.addEventListener('click', () => {
      nav.classList.add('mobile-search-active');
    });

    mobileSearchBackBtn.addEventListener('click', () => {
      nav.classList.remove('mobile-search-active');
    });
  }
});
