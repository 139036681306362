/**
 * Checks if `value` is a valid hex code.
 * @example
 *
 * isHexCode('#000')
 * // => true
 *
 * isHexCode('#000000')
 * // => true
 *
 * isHexCode('#zzz')
 * // => false
 *
 * isHexCode('#YYY')
 * // => false
 */
export default function isHexCode(value: string): boolean {
  return /^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(value);
}
