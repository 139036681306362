/* eslint-disable no-param-reassign */
import autocomplete from 'autocompleter';
import axios from 'axios';
import Turbolinks from 'turbolinks';

const performSearch = (query, update, labelAttr = 'name', path = '/api/v2/search') => {
  const q = encodeURIComponent(query);
  axios.get(`${path}?q=${q}`).then((response) => {
    const suggestions = response.data.map((result) => {
      // If the resource is serialized in the api, it may have
      // and attributes top level. Hacky way to get around that as long
      // as title or name are used for the label
      if (labelAttr === 'attributes') {
        const label = result[labelAttr].title ? result[labelAttr].title : result[labelAttr].name;
        return { label, value: result.attributes.id };
      }

      return { label: result[labelAttr], value: result.id };
    });
    update(suggestions);
  });
};

const options = (opts) => (
  {
    input: opts.input,
    minLength: 2,
    disableAutoSelect: true,
    preventSubmit: opts.preventSubmit,
    emptyMsg: 'Nothing found with that search term',
    fetch: (query, update) => { performSearch(query, update, opts.label, opts.path); },
    onSelect(item, input) {
      opts.input.value = item.label;
      if (opts.onSelectPath) {
        const url = opts.onSelectPath.replace('___ID___', item.value);
        Turbolinks.visit(url);
      } else input.form.submit();
    },
    debounceWaitMs: 200,
  }
);

document.addEventListener('turbolinks:load', () => {
  const autocompleteFields = [...document.querySelectorAll('.autocomplete-input')];
  autocompleteFields.forEach((input) => {
    // onSelectPath should be a window redirect when the item is selected
    const {
      label, path, type, onSelectPath,
    } = input.dataset;
    const opts = options({
      input, label, path, type, onSelectPath, preventSubmit: onSelectPath,
    });
    if (type) opts.emptyMsg = `No ${type} found with that search term`;
    autocomplete(opts);
  });
});
