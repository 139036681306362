/* eslint-disable class-methods-use-this */

const createPromise = (getValue) =>
  new Promise((resolve, reject) => {
    try {
      const value = getValue();
      resolve(value);
    } catch (err) {
      reject(err);
    }
  });

export default class AsyncLocalStorage {
  getItem(key) {
    return createPromise(() => window.localStorage.getItem(key));
  }

  setItem(key, value) {
    return createPromise(() => window.localStorage.setItem(key, value));
  }

  removeItem(key) {
    return createPromise(() => window.localStorage.removeItem(key));
  }
}
