/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { constructURL } from 'helpers/url';
import consumer from './consumer';
import log from '../lib/logger';
import AsyncLocalStorage from '../lib/async-localstorage';
import * as c from '../rooms/components/seamless-swap/constants';

export default class RoomVisitorChannel {
  constructor(options = {}) {
    this.roomId = options.roomId;
    this.line = options.line; // Pass in object with participant IDs
    this.store = options.store; // Vuex store
    this.channel = null;
    this.isResolved = null;
  }

  init = () =>
    new Promise((resolve) => {
      this.isResolved = resolve;
      this.createSubscription();
    });

  createSubscription = () => {
    const { line, roomId, store, isResolved } = this;

    this.channel = consumer.subscriptions.create(
      {
        channel: 'RoomVisitorChannel',
        room: `${roomId}_${line.value}`,
      },
      {
        connected() {
          isResolved();
          // Called when the subscription is ready for use on the server
          log.info('Room visitor channel connected');
          log.debug('Room visitor channel', line);
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        async received(data) {
          // Called when there's incoming data on the websocket for this channel
          // Create a subscription to this new channel
          log.debug('Received visitor channel message', data);
          if (data.type === 'message') {
            log.debug('Visitor channel message', data);
            const publishedTo = `${roomId}_${line.value}`;
            if (store) {
              store.commit('frontDeskChat/storeChatMessage', {
                line: publishedTo,
                id: data.id,
                message: data.message,
                sentBy: data.sent_by,
              });
              store.commit(
                'updateVisitor',
                {
                  requestedHelp: false,
                },
                { root: true }
              );
            }
          } else if (data.type === 'confirm_patient_id') {
            // Ignore action of room owner
            if (store.state.ownerId === store.state.visitor.id) return;

            await axios.post('/api/v2/teletherapy/confirm-p', { value: data.value });
            // reload so we can reset all subscriptions and properly show patient
            window.location.reload();
          } else if (data.type === 'assign_event') {
            if (store.state.ownerId === store.state.visitor.id) return;

            // `recurrenceId` should be in UTC to handle DST
            await axios.post('/api/v2/teletherapy/confirm-a', { value: data.value, start_time: data.recurrenceId });
            const url = constructURL(window.location.href, { recurrence_id: data.recurrenceId, t: data.id });
            window.location.href = url;
          } else if (data.type === 'assign_appointment') {
            // Ignore action of room owner
            if (store.state.ownerId === store.state.visitor.id) return;
            await axios.post('/api/v2/teletherapy/confirm-a', { value: data.value });
            // reload so we can reset all subscriptions and properly show patient
            window.location.reload();
          } else if (data.type === 'seamless_swap') {
            if (store.state.ownerId === store.state.visitor.id) return;

            const { eventId, patientId, eventStartTime } = data;
            if (!eventId || !patientId) {
              // Show session rating modal for non-continuing patients
              store.commit('layout/setCallRatingVisibility', true);
              return;
            }

            try {
              await axios.post('/api/v2/teletherapy/confirm-a', { value: eventId, start_time: eventStartTime });
              const { data: eventPatient } = await axios.get(`/api/v2/events/${eventId}/patients/${patientId}`, {
                params: {
                  recurrence_id: eventStartTime,
                },
              });

              // localStorage item to be used in WaitingArea `sessionReadyToJoin` watcher
              await new AsyncLocalStorage().setItem(c.LOCAL_STORAGE_KEY, 'true');
              const url = constructURL(window.location.href, {
                recurrence_id: eventPatient.recurrenceId,
                t: eventPatient.id,
              });
              window.location.href = url;
            } catch (error) {
              window.location.href = '/';
            }
          }
        },
      }
    );
  };
}
