import { isEmpty } from '../../helpers/metal';
import { observeMutations } from '../../helpers/observers';

export default class CommandPaletteStack extends HTMLElement {
  connectedCallback() {
    this.activateNamedGroup = this.activateNamedGroup.bind(this);
    this.activateDefaultGroup = this.activateDefaultGroup.bind(this);

    this.addEventListener('command-palette:scopeUpdated', this.activateNamedGroup);
    this.addEventListener('command-palette:scopeRemoved', this.activateDefaultGroup);

    observeMutations(this.toggleDataEmpty.bind(this), this, {
      childList: true,
      subtree: true,
      attributeFilter: ['hidden'],
    });
  }

  disconnectedCallback() {
    this.removeEventListener('command-palette:scopeUpdated', this.activateNamedGroup);
    this.removeEventListener('command-palette:scopeRemoved', this.activateDefaultGroup);
  }

  /**
   * Hide all other groups except the active one
   */
  activateNamedGroup(event) {
    const object = JSON.parse(event.detail);
    if (isEmpty(object)) return;

    const { id } = object;
    this.groups.forEach((group) => {
      group.hidden = group.dataset.groupId !== id;
    });
  }

  /**
   * Hide all other groups except the homepage
   */
  activateDefaultGroup() {
    this.groups.forEach((group) => {
      group.hidden = !group.hasAttribute('data-cp-homepage');
    });
  }

  toggleDataEmpty() {
    const allHidden = this.groups.every((group) => group.hidden);
    this.toggleAttribute('data-empty', allHidden);
  }

  get groups() {
    return Array.from(this.querySelectorAll('command-palette-group'));
  }
}
