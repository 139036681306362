import tinykeys from 'tinykeys';
import { cancel, focusable } from '../helpers/dom-helper';

export default class DetailsDialogElement extends HTMLElement {
  connectedCallback() {
    this.details = this.closest('details');
    if (!this.details) return;

    this.setAttribute('role', 'dialog');
    this.setAttribute('aria-modal', 'true');

    this.details.addEventListener('toggle', onToggle);
    this.details.addEventListener('keydown', onKeydown);
    this.details.addEventListener('click', onClick);
    this.registerActivationHotkey();

    const summary = this.details.querySelector('summary');
    if (summary) {
      if (!summary.hasAttribute('role')) summary.setAttribute('role', 'button');
    }
  }

  disconnectedCallback() {
    close(this.details);

    if (this.details) {
      this.details.removeEventListener('toggle', onToggle);
      this.details.removeEventListener('keydown', onKeydown);
      this.details.removeEventListener('click', onClick);
    }
  }

  registerActivationHotkey() {
    const hotkey = this.getAttribute('data-activation-hotkey');
    if (!hotkey) return;

    tinykeys(window, {
      [hotkey]: (event) => {
        cancel(event);

        if (this.details.hasAttribute('open')) {
          close(this.details);
        } else {
          open(this.details);
        }
      },
    });
  }
}

function onToggle(event) {
  const details = event.currentTarget;

  if (details.hasAttribute('open')) {
    details.dispatchEvent(new CustomEvent('details-dialog:open', { bubbles: true }));
    open(details);
  } else {
    close(details);
    details.dispatchEvent(new CustomEvent('details-dialog:close', { bubbles: true }));
  }
}

function onKeydown(event) {
  const details = event.currentTarget;
  if (event.key === 'Escape') {
    close(details);
    cancel(event);
  }
}

function onClick(event) {
  const { target } = event;
  if (!(target instanceof HTMLElement)) return;

  const details = target.closest('details');
  if (!details) return;

  if (target.closest('[data-dialog-close]')) {
    close(details);
  }
}

function open(details) {
  const dialog = details.querySelector('details-dialog');

  details.setAttribute('open', '');
  if (dialog) {
    dialog.addEventListener('submit', checkFormSubmission);
    dialog.addEventListener('keydown', interceptTabbing);
    focusFirstElement(dialog);
  }
}

function close(details) {
  details.removeAttribute('open');
  const dialog = details.querySelector('details-dialog');

  if (dialog) {
    dialog.removeEventListener('submit', checkFormSubmission);
    dialog.removeEventListener('keydown', interceptTabbing);
    dialog.removeAttribute('tabindex');
  }

  const summary = details.querySelector('summary');
  if (summary && summary.getAttribute('role') !== 'none') summary.focus();
}

function interceptTabbing(event) {
  if (event.key !== 'Tab') return;
  const dialog = event.currentTarget;

  event.preventDefault();

  const focusableElements = Array.from(dialog.querySelectorAll('*')).filter(focusable);
  if (focusableElements.length === 0) return;

  const movement = event.shiftKey ? -1 : 1;
  const root = this.getRootNode();
  const currentFocus = this.contains(root.activeElement) ? root.activeElement : null;
  let targetIndex = movement === -1 ? -1 : 0;

  if (currentFocus instanceof HTMLElement) {
    const currentIndex = focusableElements.indexOf(currentFocus);
    if (currentIndex !== -1) {
      targetIndex = currentIndex + movement;
    }
  }

  if (targetIndex < 0) {
    targetIndex = focusableElements.length - 1;
  } else {
    targetIndex %= focusableElements.length;
  }

  focusableElements[targetIndex].focus();
}

function focusFirstElement(dialog) {
  const autofocusableElement = Array.from(dialog.querySelectorAll('[autofocus]')).filter(focusable)[0];

  if (autofocusableElement) {
    autofocusableElement.focus();
  } else {
    dialog.setAttribute('tabindex', '-1');
    dialog.focus();
  }
}

function checkFormSubmission(event) {
  const form = event.target;
  if (!(form instanceof HTMLFormElement)) return;

  if (form.getAttribute('method') === 'dialog') {
    event.preventDefault();

    const details = form.closest('details');
    if (details) close(details);
  }
}
