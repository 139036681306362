class RemoveFields {
  // This executes when the function is instantiated.
  constructor(options = {}) {
    this.iterateLinks();
    this.options = options;
  }

  iterateLinks = () => {
    // There is another way to stop delete based off the class added.
    // It is useful for having multiple of the same kind of add field in the same form.
    // Please see the  STOP DELETE OPTION 2: START section.
    const firstDelete = $('.remove_fields');
    if (firstDelete.length) {
      if (firstDelete[0].getAttribute('data-no-last-delete')) {
        firstDelete[0].setAttribute('disabled', true);
        firstDelete.addClass('disabled');
      }
    }
    // Use event delegation to ensure any fields added after the page loads are captured.
    $(document).on('click', '.remove_fields', (e) => {
      this.handleClick(e.target, e);
    });
  };

  handleClick = (link, e) => {
    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return;
    // Prevent the browser from following the URL.
    e.preventDefault();
    // Find the parent wrapper for the set of nested fields.
    const fieldParent = link.closest('.nested-fields');
    // default to no-data-target-found as undefined target could be passed somewhere in the dom with a missing variable
    const target = link.getAttribute('data-target') || 'no-data-target-found';
    const dataElement = document.querySelector(`[data-fields='${target}']`);

    // Diagnosis codes validation: start
    const selectComponents = fieldParent.querySelectorAll('[alert-id]');

    if (selectComponents) {
      const alertIds = [];
      selectComponents.forEach((alert) => {
        alertIds.push(alert.getAttribute('alert-id'));
      });

      const deleteClicked = new CustomEvent('deleteClicked', {
        detail: {
          alertIds,
        },
      });

      document.dispatchEvent(deleteClicked);
    }
    // Diagnosis codes validation: end

    // STOP DELETE OPTION 2: START
    if (dataElement && link.classList.contains('no_last_delete')) {
      const removeButtons = dataElement.querySelectorAll('.nested-fields');
      const visibleElements = Array.from(removeButtons).filter(
        (element) => element.style.display !== 'none' && getComputedStyle(element).display !== 'none'
      );

      const removeLinks = dataElement.querySelectorAll('.remove_fields.no_last_delete');

      if (removeLinks.length > 0) {
        removeLinks[0].textContent = '';
      }

      if (visibleElements.length <= 1) return;
    }
    // STOP DELETE OPTION 2: END

    // Remove required attribute from fields otherwise that will cause
    // the following error "An invalid form control with name='' is not focusable"
    // when the user tries to click the form submission button.
    // https://stackoverflow.com/questions/22148080/an-invalid-form-control-with-name-is-not-focusable
    const requiredElements = fieldParent.querySelectorAll('[required]');
    for (let i = 0; i < requiredElements.length; i += 1) {
      const element = requiredElements[i];
      element.required = false;
    }
    // toggle button for has-one-relationship
    if (dataElement && dataElement.querySelectorAll('.add_fields.has-one-relationship')[0]) {
      dataElement.querySelectorAll('.add_fields.has-one-relationship')[0].classList.toggle('d-none');
    }
    // If there is a parent wrapper, find the hidden delete field.
    const deleteField = fieldParent ? fieldParent.querySelector('input[type="hidden"]') : null;
    // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
    if (deleteField) {
      deleteField.value = 1;
      fieldParent.style.display = 'none';
      const nestedFields = Array.from(document.querySelectorAll('.nested-fields')).filter(
        (f) => f.style.display !== 'none'
      );
      if (!nestedFields.length) $('.nested-field-save').hide();
    } else if (fieldParent.dataset.destroy) {
      fieldParent.remove();
    }
  };
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new RemoveFields());
