import { GoalEditorSetup } from './trix-editor-configs';

const trix = require('trix');

window.addEventListener('trix-before-initialize', (event) => {
  const goalEditor = document.getElementById('goal-editor');
  if (goalEditor) {
    GoalEditorSetup(trix, event);
  }
});
