const speech = "babd3761-8f1a-444f-8a5f-0274356dd1ea";
const occupational = "648dddb8-aff6-4ddd-a366-1ad662d015fb";
const physical = "5426ba7e-4c68-4245-8486-49cb236481e1";
const feeding = "9361c1a2-4a5d-4257-8bda-c41c15207364";
const STUDENT = "95ff432d-b431-46f3-bbf7-eae2ac43be0b";
const CF_SLP = "3c27a747-67bf-4d28-8f47-4098e363f79e";
const CCC_SLP = "6fe1fec2-6290-4e78-8734-21f3bf483d64";
const SLPA = "10035165-37ae-4d7c-b660-ed5dcb62853b";
const OTA = "bde804e1-4781-4b01-8eb1-b8ba49fd3a67";
const COTA = "84f30885-7e8d-4ea1-92e4-cf353c0b8aad";
const OTR_L = "b0617ed7-f834-40d6-a669-c86c3416463d";
const PTA = "c7400581-49a0-4e92-9f70-0231f5577f13";
const CPTA = "2340e5cf-8ef8-481e-96a1-da0421772ec7";
const PT = "91cfc361-083a-4c45-a56f-a842c5b75118";

const disciplines = [
  "babd3761-8f1a-444f-8a5f-0274356dd1ea",
  "648dddb8-aff6-4ddd-a366-1ad662d015fb",
  "5426ba7e-4c68-4245-8486-49cb236481e1",
  "9361c1a2-4a5d-4257-8bda-c41c15207364"
];

const revealDisciplinesList = () => {
  document.querySelector('#users_disciplines_div').classList.remove('d-none');
};

const hideDisciplinesList = () => {
  document.querySelector('#users_disciplines_div').classList.add('d-none');
};

const resetDiscipline = (id) => {
  document.querySelector(`#user_discipline_ids_${id}`).checked = false;
  document.querySelector(`#user_discipline_ids_${id}`).closest('.custom-checkbox').classList.add('d-none');
};

const resetAllDisciplines = () => {
  for (var i = 0; i < disciplines.length; i++) {
    resetDiscipline(disciplines[i]);
  }
};

const currentlyChecked = () => {
  const checked = [];
  for (var i = 0; i < disciplines.length; i++) {
    var id = disciplines[i];
    if (document.querySelector(`#user_discipline_ids_${id}:checked`) !== null) {
      checked.push(id);
    }
  }
  return checked;
};

const showCheckbox = (id) => {
  document.querySelector(`#user_discipline_ids_${id}`).closest('.custom-checkbox').classList.remove('d-none');
};

const checkCheckbox = (id) => {
  document.querySelector(`#user_discipline_ids_${id}`).checked = true;
};

const computeDisciplines = (credentialIds, reset = true) => {
  revealDisciplinesList();
  const checked = currentlyChecked();

  if (reset) {
    resetAllDisciplines();
  }

  if (credentialIds.includes(STUDENT)) {
    showCheckbox(speech);
    if (checked.includes(speech)) { checkCheckbox(speech); }
    showCheckbox(occupational);
    if (checked.includes(occupational)) { checkCheckbox(occupational); }
    showCheckbox(physical);
    if (checked.includes(physical)) { checkCheckbox(physical); }
    showCheckbox(feeding);
    if (checked.includes(feeding)) { checkCheckbox(feeding); }
  }

  if (credentialIds.includes(CF_SLP) || credentialIds.includes(CCC_SLP) || credentialIds.includes(SLPA)) {
    showCheckbox(speech);
    if (checked.includes(speech)) { checkCheckbox(speech); }
    showCheckbox(feeding);
    if (checked.includes(feeding)) { checkCheckbox(feeding); }
  }

  if (credentialIds.includes(OTR_L) || credentialIds.includes(COTA) || credentialIds.includes(OTA)) {
    // As we don't want to give them the ability to uncheck the discipline
    // and since there will be only one checkbox option, there is no need to
    // so this to the user
    // showCheckbox(occupational);
    checkCheckbox(occupational);
    hideDisciplinesList();
  }

  if (credentialIds.includes(PT) || credentialIds.includes(PTA) || credentialIds.includes(CPTA)) {
    // As we don't want to give them the ability to uncheck the discipline
    // and since there will be only one checkbox option, there is no need to
    // so this to the user
    // showCheckbox(physical);
    checkCheckbox(physical);
    hideDisciplinesList();
  }
};

export default computeDisciplines;
