/**
 * @param {Function} callback
 * @param {HTMLElement} target
 * @param {MutationObserverInit} options
 */
// eslint-disable-next-line import/prefer-default-export
export function observeMutations(callback, target, options) {
  const observer = new MutationObserver((mutations) => {
    observer.disconnect();
    Promise.resolve().then(start);
    callback.call(this, mutations);
  });

  function start() {
    if (target.isConnected) observer.observe(target, options);
  }

  start();
}
