import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['controlledIcon', 'controlledButtons', 'controlledValues'];

  toggleAddOn = (event) => {
    const element = event.target;
    this.iconToggle(!element.classList.contains('bi-toggle-on'), element.classList);

    let normal,
      flexible,
      therapy_tools,
      teletherapy = false;

    this.controlledIconTargets.forEach((elem) => {
      if (elem.dataset.controlledIcon === 'therapy_tools' && elem.classList.contains('bi-toggle-on'))
        therapy_tools = true;
      if (elem.dataset.controlledIcon === 'teletherapy' && elem.classList.contains('bi-toggle-on'))
        teletherapy = true;
    });

    if (therapy_tools && teletherapy) flexible = true;
    if (!therapy_tools && !teletherapy && !flexible) normal = true;

    if (flexible) {
      this.processButtons('b-flexible');
      this.processValues('v-flexible');
    }
    else {
      if (therapy_tools) {
        this.processButtons('b-therapy_tools');
        this.processValues('v-therapy_tools');
      }
      else if (teletherapy) {
        this.processButtons('b-teletherapy');
        this.processValues('v-teletherapy');
      } else {
        this.processButtons('b');
        this.processValues('v');
      }
    }
  };

  processButtons(active) {
    this.controlledButtonsTargets.forEach((item) => {
      if (item.dataset.controlledButtons === active) {
        item.classList.remove('d-none');
      } else {
        item.classList.add('d-none');
      }
    });
  }

  processValues(active) {
    this.controlledValuesTargets.forEach((item) => {
      if (item.dataset.controlledValues === active) {
        item.classList.remove('d-none');
      } else {
        item.classList.add('d-none');
      }
    });
  }

  iconToggle(on, icon) {
    if (on) {
      icon.remove('bi-toggle-off');
      icon.add('bi-toggle-on', 'text-primary');
    } else {
      icon.remove('bi-toggle-on', 'text-primary');
      icon.add('bi-toggle-off');
    }
  }
}
