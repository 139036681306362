// Selectively require necessary controllers for the front_pages

import { Application } from '@hotwired/stimulus';
import ScrollReveal from 'stimulus-scroll-reveal';

import FocusController from './focus_controller';
import LandingPageNavController from './landing_page_nav_controller';
import PopCardController from './pop_card_controller';
import PricingPageController from './pricing_page_controller';
import SideNavController from './side_nav_controller';
import ToastController from './toast_controller';
import ToggleSectionController from './toggle_section_controller';
import TrayCardController from './tray_card_controller';
import TrayNavController from './tray_nav_controller';
import CarouselController from './carousel_controller';
import SectionAnchorController from './section_anchor_controller';

const application = Application.start();

window.Stimulus = application;

application.register('focus', FocusController);
application.register('pop-card', PopCardController);
application.register('pricing-page', PricingPageController);
application.register('landing-page-nav', LandingPageNavController);
application.register('scroll-reveal', ScrollReveal);
application.register('side-nav', SideNavController);
application.register('toast', ToastController);
application.register('toggle-section', ToggleSectionController);
application.register('tray-card', TrayCardController);
application.register('tray-nav', TrayNavController);
application.register('carousel', CarouselController);
application.register('section-anchor', SectionAnchorController);
