import ApplicationController from './application_controller';

/**
 *
 * This controls the side-navigation components active index and hides/displays the relevant items
 * to the right of the navigation container.
 *
 * @controller side-nav
 * @example please look at the site_map.html.erb and the relevant controller.
 *
 */
export default class extends ApplicationController {
  static targets = ['controlled', 'uncontrolled'];
  static values = { index: String, hasRunFirst: Boolean };

  /**
   *
   * Updates the active index.
   * @function changeActiveIndex
   * @param {*} event
   *
   */
  changeActiveIndex(event) {
    const element = event.target;
    this.indexValue = element.dataset.uncontrolledIndex;
    this.showCurrentSlide();
  }

  /**
   *
   * Loops through the related subItems of the side navigation items
   * updates relevant classes and hides/shows the relevant items
   * based on the active index.
   * @function showCurrentSlide
   *
   */
  showCurrentSlide() {
    this.controlledTargets.forEach((element) => {
      if (element.dataset.controlledIndex === this.indexValue) {
        element.classList.remove('d-none');
      } else {
        element.classList.add('d-none');
      }
    });
  }
}
