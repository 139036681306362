import { metaValue } from 'helpers/dom-helper';
import consumer from './consumer';

export default class CalendarEventChannel {
  constructor(element) {
    this.element = element; // Impulse calendar instance
    const { currentOrgId } = this.element;

    this.channel = consumer.subscriptions.create(
      {
        channel: 'CalendarEventChannel',
        organization_id: currentOrgId,
      },
      {
        received: this.received.bind(this),
      }
    );
  }

  received(payload) {
    if (payload.cid === this.cid) return; // Don't broadcast to the same client.

    switch (payload.action) {
      case 'create':
        this.element.eventCreateDelegate.addEvent(payload.event);
        break;
      case 'update':
        this.updateEvent(payload);
        break;
      case 'destroy':
        this.destroyEvent(payload);
        break;
    }
  }

  /**
   * @private
   */
  updateEvent({ event }) {
    const delegate = this.element.eventModifyDelegate;
    switch (event.metadata.update_type) {
      case '1': // Current
        delegate.updateCurrent(event);
        break;
      case '2': // This and all following
        delegate.updateThisAndFollowing(event);
        break;
      default: // All events
        delegate.updateAll(event);
        break;
    }
  }

  /**
   * @private
   */
  destroyEvent({ event, metadata }) {
    const delegate = this.element.eventDestroyDelegate;
    switch (metadata.removal_type) {
      case '1': // Current
        delegate.destroyCurrent(event.uid);
        break;
      case '2': // This and all following
        delegate.destroyThisAndFollowing(event.start_time, event.id);
        break;
      default:
        delegate.destroyAll(event.id);
        break;
    }
  }

  /**
   * The fullcalendar instance.
   */
  get calendar() {
    return this.element.calendar;
  }

  /**
   * The client id.
   * @returns {string|null}
   */
  get cid() {
    return metaValue('client-id');
  }

  /**
   * The current org id
   * @returns {string|null}
   */
  get orgid() {
    return metaValue('current-org-id')
  }
}
