/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import { metaValue } from 'helpers/dom-helper';

export default class extends Controller {
  /**
   * @description Find out if the page is still in preview mode. This happens when a user transitions from one page
   * to another via turbolinks
   * @returns {boolean}
   */
  get isTurbolinksPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  }

  /**
   * @description Get current user's tzinfo
   */
  get tzinfo() {
    return metaValue('current-user-tzinfo') || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * @description Get current_user's current_org id
   * @returns {string}
   */
  get currentOrgId() {
    return metaValue('current-org-id') || '';
  }
}
