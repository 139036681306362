/* eslint-disable no-underscore-dangle */
import consumer from './consumer';
import log from '../lib/logger';

export default class RoomChat {
  constructor(options = {}) {
    this.roomsSessionId = options.roomsSessionId;
    this.line = options.line; // Pass in object with participant IDs
    this.username = options.username; // An identifier in this channel
    this.store = options.store; // Vuex store
    this.channel = null;
  }

  async init() {
    await this.createSubscription();
  }

  createSubscription() {
    const {
      line, roomsSessionId, store,
    } = this;

    if (line === undefined) return;

    this.channel = consumer.subscriptions.create({
      channel: 'RoomChatChannel',
      room: `${roomsSessionId}_${line.value}`,
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        log.info('Chat channel connected');
        log.debug('Chat channel', line);
        if (store) store.dispatch('chat/storeLine', line);
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        if (this.channel) this.channel.unsubscribe();
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        // Store message in Vuex
        if (data.type === 'message') {
          const publishedTo = `${roomsSessionId}_${line.value}`;
          if (store) {
            store.commit('chat/setActiveLine', line.value);
            store.commit('chat/storeChatMessage', {
              line: publishedTo,
              id: data.id,
              message: data.message,
              sentBy: data.sent_by,
              sentAt: data.sent_at,
              username: data.username,
            });
          }
        } else if (data.type === 'request_dm_line') {
          if (store.state.visitor.id !== data.id) {
            const { publicChat } = store.state.chat;
            if (!publicChat) return;
            // Send a message on the public channel so other subscribers can also
            // subscribe to this direct channel
            publicChat.send({
              type: 'dm_invitation',
              sentBy: store.state.visitor.id,
              directLine: store.state.chat.lines.find((l) => l.value.includes(store.state.visitor.id)),
            });
          }
        } else if (data.type === 'dm_invitation') {
          const { sentBy, directLine } = data;
          if (store && !store.state.admin.testing) {
            store.dispatch('chat/handleDMChannelInvitation', {
              sentBy, directLine, store,
            });
          }
        } else if (data.type === 'blockChatUser') {
          store.dispatch('permissions/blockChat', data.id);
        } else if (data.type === 'unBlockChatUser') {
          store.dispatch('permissions/unBlockChat', data.id);
        } else if (data.type === 'correction_received') {
          if (data.sentBy !== store.state.visitor.id) {
            store.commit('chat/storeCorrection', data.correction);
          }
        }
      },
    });
  }
}
