// app/javascript/channels/notification_channel.js

import consumer from './consumer';

consumer.subscriptions.create('Noticed::NotificationChannel', {
  connected() {},

  disconnected() {},

  received(data) {
    const count = data.unread_count;
    const unreadCount = count > 9 ? '9+' : count;
    // Dispatch an event with unread count
    const event = new CustomEvent('notifications-unread-count', {
      detail: {
        unreadCount,
      },
    });
    window.dispatchEvent(event);
  },
});
