import ApplicationController from './application_controller';

/**
 * @example
 *
 * <button type="button" data-controller="toast" data-toast-target-id-value="clipboard-toast" data-action="toast#show">
 *   Open toast
 * </button>
 * <div id="clipboard-toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000" class="toast toast-success hide">
 *   <div class="toast-header">
 *     <i class="bi bi-check-circle-fill text-success mr-2"></i>
 *     <p class="mr-auto mb-0">Copied to clipboard!</p>
 *   </div>
 * </div>
 *
 * To handle multiple toasts in a single element:
 *
 * <button type="button" data-controller="toast" data-toast-type-value="{'click': 'clipboard-toast', 'focus': 'updated-toast'}" data-action="click->toast#show focus->toast#show">
 *   Open toast
 * </button>
 */

export default class extends ApplicationController {
  static values = { targetId: String, type: Object };

  initialize() {
    this.hide = this.hide.bind(this);
  }

  disconnect() {
    $(this.toast).off('hidden.bs.toast', this.hide);
  }

  targetIdValueChanged(value) {
    const toast = document.getElementById(value);
    $(toast).on('hidden.bs.toast', this.hide);
  }

  show(event) {
    this.setToastId(event.type);

    $(this.toast).toast('show');
    this.element.setAttribute('aria-describedby', this.toast.id);
  }

  hide() {
    this.element.removeAttribute('aria-describedby');
  }

  // Private

  setToastId(type) {
    if (!this.hasTypeValue || !type) return;

    const toastId = this.typeValue[type];
    if (!toastId) return;

    this.targetIdValue = toastId;
  }

  get toast() {
    return document.getElementById(this.targetIdValue);
  }
}
