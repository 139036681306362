import { Datepicker } from 'vanillajs-datepicker';

// make sure not to make additional dropdowns
const removeExisting = () => {
  const existing = document.querySelectorAll('.datepicker.datepicker-dropdown');
  existing.forEach((dropdown) => dropdown.remove());
};

const initializeDatepickers = () => {
  const datepickers = document.querySelectorAll('[data-datepicker]');
  if (datepickers.length) {
    removeExisting(datepickers);
    const sharedOptions = {
      buttonClass: 'btn',
      format: 'y-mm-dd',
    };
    datepickers.forEach((input) => {
      const options = JSON.parse(input.dataset.datepicker);
      if (options.maxDate === 'today') options.maxDate = new Date();
      const combinedOptions = { ...sharedOptions, ...options };
      const dp = new Datepicker(input, combinedOptions);
    });
  }
};

export default initializeDatepickers;
