export function nextFrame() {
  return new Promise(requestAnimationFrame);
}

export function nextEventLoop() {
  return new Promise((resolve) => setTimeout(() => resolve(), 0));
}

/**
 * @param {HTMLElement} transitionElement
 * @returns {Promise<void>}
 * @example
 * async open() {
 *   await waitForTransition(myElement);
 *   // do something
 * }
 */
export function waitForTransition(transitionElement) {
  const durationPadding = 5;
  const duration = getTransitionDurationFromElement(transitionElement) + durationPadding;
  let called = false;

  const handler = ({ target }) => {
    if (target !== transitionElement) return;

    called = true;
    transitionElement.removeEventListener('transitionend', handler);
  };

  transitionElement.addEventListener('transitionend', handler);
  return new Promise((resolve) => {
    setTimeout(() => {
      if (!called) {
        transitionElement.dispatchEvent(new Event('transitionend'));
      }
      resolve();
    }, duration);
  });
}

/**
 * @param {HTMLElement} element
 * @returns {number}
 */
export function getTransitionDurationFromElement(element) {
  if (!element) return 0;

  let { transitionDuration, transitionDelay } = window.getComputedStyle(element);
  const floatTransitionDuration = Number.parseFloat(transitionDuration);
  const floatTransitionDelay = Number.parseFloat(transitionDelay);
  // Return 0 if element or transition duration is not found
  if (!floatTransitionDuration && !floatTransitionDelay) return 0;

  // eslint-disable-next-line prefer-destructuring
  transitionDuration = transitionDuration.split(',')[0];
  // eslint-disable-next-line prefer-destructuring
  transitionDelay = transitionDelay.split(',')[0];
  return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * 1000;
}
