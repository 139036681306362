// Config and trix before-initialize listeners set up specifically for Goal Editor
import { goalsTrixInitialize } from '../goals-editor';

const GoalEditorSetup = (trix) => {
  // Create callbacks
  document.addEventListener('trix-initialize', goalsTrixInitialize);

  trix.config.textAttributes['gc-who'] = {
    tagName: 'gc-who',
  };
  trix.config.textAttributes['gc-skill'] = {
    tagName: 'gc-skill',
  };
  trix.config.textAttributes['gc-context'] = {
    tagName: 'gc-context',
  };
  trix.config.textAttributes['gc-measurable'] = {
    tagName: 'gc-measurable',
  };
  trix.config.textAttributes['gc-reason'] = {
    tagName: 'gc-reason',
  };
  trix.config.textAttributes['gc-timeframe'] = {
    tagName: 'gc-timeframe',
  };
};

export default GoalEditorSetup;
