import Combobox from '@ambiki/combobox';
import { visible, cancel, enabled } from '../helpers/dom-helper';
import { nextFrame } from '../helpers/timing';

export default class CommandPaletteElement extends HTMLElement {
  /**
   * @example
   * Key is keyboard token. Value is scope id.
   * { "@": "users", "alist:": "visual-schedules", "goal:": "goals" }
   */
  keyMappings = {};

  async connectedCallback() {
    this.input = this.querySelector('input');
    this.list = this.querySelector('command-palette-stack');

    if (!(this.input instanceof HTMLInputElement) || !this.list) return;

    // Registering before `input` addEventListener
    this.registerKeyMappings();

    this.combobox = new Combobox(this.input, this.list);
    this.list.setAttribute('role', 'presentation');

    this.onKeydown = this.onKeydown.bind(this);
    this.onInput = this.onInput.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);

    this.input.addEventListener('keydown', this.onKeydown);
    this.input.addEventListener('input', this.onInput);
    this.addEventListener('command-palette-group:loadstart', this.startLoading);
    this.addEventListener('command-palette-group:loadend', this.stopLoading);

    await nextFrame();
    this.initializePalette();

    this.details = this.closest('details');
    if (!this.details) return;

    this.onToggle = this.onToggle.bind(this);
    this.details.addEventListener('toggle', this.onToggle);
  }

  disconnectedCallback() {
    this.destroyPalette();

    this.removeEventListener('command-palette-group:loadstart', this.startLoading);
    this.removeEventListener('command-palette-group:loadend', this.stopLoading);
    this.input.removeEventListener('keydown', this.onKeydown);
    this.input.removeEventListener('input', this.onInput);
    this.details.removeEventListener('toggle', this.onToggle);
  }

  static get observedAttributes() {
    return ['data-active-group'];
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    switch (name) {
      case 'data-active-group': {
        const parsedNewValue = JSON.parse(newValue);
        if (parsedNewValue) {
          this.input.value = '';
          this.groups.forEach(filterGroup('', { scopeId: parsedNewValue.id })); // After we set the scope, groups can start fetching results from the API
        } else {
          this.groups.forEach(filterGroup('', { scopeId: null })); // Reset filtered items and groups when a scope is removed
        }

        await nextFrame();
        this.combobox.setActive(this.activableItems[0]);
        break;
      }
      default:
        break;
    }
  }

  onToggle() {
    if (this.details.hasAttribute('open')) {
      this.initializePalette();
    } else {
      this.destroyPalette();
    }
  }

  /**
   * Clean up all things here
   */
  destroyPalette() {
    this.combobox.stop();
    this.input.value = '';
    removeScope(this);
    this.removeAttribute('data-active-group');
    this.header?.removeAttribute('loading');
    this.items.forEach((item) => item.setAttribute('aria-selected', 'false'));
  }

  onKeydown(event) {
    switch (event.key) {
      case 'Enter':
        if (commit(this)) {
          cancel(event);
        }
        break;
      case 'Tab':
        if (updateScope(this)) {
          cancel(event);
        }
        break;
      case 'Backspace':
        if (removeScope(this)) {
          cancel(event);
        }
        break;
      default:
        break;
    }
  }

  onInput() {
    const query = this.input.value.trim();
    const targetGroupId = this.keyMappings[query.toLowerCase()] || '';
    const item = this.querySelector(`[data-target-group-id="${targetGroupId}"]`);
    if (item && updateScopeFromItem(item, this)) return;

    const activeGroup = this.getAttribute('data-active-group');
    const parsedActiveGroup = JSON.parse(activeGroup);

    this.groups.forEach(filterGroup(query, { scopeId: parsedActiveGroup?.id }));
    this.combobox.setActive(this.activableItems[0]);
  }

  startLoading() {
    this.header?.setAttribute('loading', '');
  }

  stopLoading() {
    this.combobox.setActive(this.activableItems[0]); // Activate first option after fetching from API
    this.header?.removeAttribute('loading', '');
  }

  registerKeyMappings() {
    this.items
      .filter((item) => item.hasAttribute('data-trigger-keys'))
      .forEach((mapping) => {
        const { triggerKeys, targetGroupId } = mapping.dataset;
        const parsedTriggerKeys = JSON.parse(triggerKeys);
        if (!Array.isArray(parsedTriggerKeys)) {
          throw new Error('data-trigger-keys should be an array');
        }

        parsedTriggerKeys.forEach((key) => {
          if (this.keyMappings[key]) throw new Error(`Duplicate key detected: ${key}`);
          this.keyMappings[key.toLowerCase()] = targetGroupId;
        });
      });
  }

  initializePalette() {
    this.groups.forEach(filterGroup('', { scopeId: null }));
    this.combobox.start();
    this.combobox.setActive(this.activableItems[0]);
    const autofocus = this.querySelector('[autofocus]');
    autofocus?.focus();
  }

  get groups() {
    return Array.from(this.querySelectorAll('command-palette-group'));
  }

  get header() {
    return this.querySelector('command-palette-header');
  }

  get items() {
    return Array.from(this.querySelectorAll('command-palette-item'));
  }

  get activableItems() {
    return this.items.filter(activable);
  }
}

function commit(palette) {
  const item = palette.querySelector('[data-tracking]');
  if (!item) return false;
  if (item.getAttribute('aria-disabled') === 'true') return false;

  const anchor = item.firstElementChild;
  if (!anchor) return false;

  // When `href` is `#`, we just want to open the nested links, if any on Enter
  const href = anchor.getAttribute('href');
  if (href === '#') {
    updateScope(palette);
  } else {
    anchor.click();
  }
  return true;
}

function updateScope(palette) {
  const item = palette.querySelector('[data-tracking]');
  return updateScopeFromItem(item, palette);
}

function updateScopeFromItem(item, palette) {
  if (!(item instanceof HTMLElement)) return false;
  if (item.getAttribute('aria-disabled') === 'true') return false;

  const groupId = item.getAttribute('data-target-group-id');
  const groupTitle = item.getAttribute('data-target-group-name');
  if (!groupId || !groupTitle) return false;

  // Check if group is already active
  const activeGroup = JSON.parse(palette.getAttribute('data-active-group'));
  if (activeGroup?.id === groupId) return false;

  const targetGroup = palette.querySelector(`[data-group-id="${groupId}"]`);
  if (!targetGroup) return false;

  const header = palette.querySelector('command-palette-header');
  const stack = palette.querySelector('command-palette-stack');
  if (!header || !stack) return false;

  // Inform other elements about the active group
  const stringifiedObject = JSON.stringify({ id: groupId, title: groupTitle });
  palette.setAttribute('data-active-group', stringifiedObject);
  header.dispatchEvent(new CustomEvent('command-palette:scopeUpdated', { detail: stringifiedObject }));
  stack.dispatchEvent(new CustomEvent('command-palette:scopeUpdated', { detail: stringifiedObject }));

  return item;
}

function removeScope(palette) {
  const header = palette.querySelector('command-palette-header');
  const stack = palette.querySelector('command-palette-stack');
  if (!header || !stack) return false;

  // Only remove if we have an active group and the input's length is 0
  if (palette.hasAttribute('data-active-group') && !palette.input.value.length) {
    palette.removeAttribute('data-active-group');
    header.dispatchEvent(new CustomEvent('command-palette:scopeRemoved'));
    stack.dispatchEvent(new CustomEvent('command-palette:scopeRemoved'));
    return true;
  }

  return false;
}

function filterGroup(query, { scopeId }) {
  return (group) => {
    group.dispatchEvent(new CustomEvent('command-palette:change', { detail: { query, scopeId } }));
  };
}

function activable(item) {
  return visible(item) && enabled(item);
}
