/* eslint-disable no-return-assign */
import ApplicationController from './application_controller';
import { closeOnEsc } from '../lib/helpers';

export default class extends ApplicationController {
  static targets = [
    'closeBtn',
    'dangerLink',
    'returnMainMenu',
    'subLevelTrayMenu',
    'subMenuContents',
    'subMenuTitle',
    'subTrigger',
    'topLevelTrayNav',
    'trayEl',
    'trigger',
  ];

  initialize() {
    this.show = false;
    this.overlay = document.getElementById('pageOverlay');
    this.zIndexBottomEls = [...document.getElementsByClassName('behind__active-tray')];
  }

  connect() {
    ['click'].forEach((event) => {
      this.triggerTarget.addEventListener(event, this.toggleElShow);
    });
    closeOnEsc(this.hideEl);
  }

  trayElTargetConnected() {
    ['click'].forEach((event) => {
      this.subTriggerTargets.forEach((subTrigger) => {
        subTrigger.addEventListener(event, () => {
          this.showSubLevel(subTrigger);
        });
      });
      if (this.hasReturnMainMenuTarget) this.returnMainMenuTarget.addEventListener(event, this.returnMainMenu);
      if (this.hasCloseBtnTarget) this.closeBtnTarget.addEventListener(event, this.hideEl);

      // Hide the sublevel menu after the click events added for the button
      if (this.hasSubLevelTrayMenuTarget) this.subLevelTrayMenuTarget.classList.add('d-none');
    });
  }

  hideEl = () => {
    this.show = false;
    document.body.style.overflow = 'inherit';
    this.zIndexBottomEls.forEach((el) => (el.style.zIndex = 'inherit'));
    this.trayElTarget.classList.add('d-none');
    if (this.overlay) this.overlay.style.display = 'none';
  };

  returnMainMenu = () => {
    // If a link could lead to an undesired result and is going to be
    // where the user's mouse is after returning to the main nav, tmp disable it
    if (this.hasDangerLinkTarget) {
      this.dangerLinkTargets.forEach((link) => {
        link.classList.add('disabled');
        setTimeout(() => {
          link.classList.remove('disabled');
        }, 1500);
      });
    }
    this.subLevelTrayMenuTarget.classList.add('d-none');
    this.topLevelTrayNavTarget.classList.remove('d-none');
  };

  showEl = () => {
    this.show = true;
    document.body.style.overflow = 'hidden';
    this.zIndexBottomEls.forEach((el) => (el.style.zIndex = -1));
    if (this.overlay) {
      // The delay ensures overlay shows if another tray trigger is clicked
      setTimeout(() => {
        this.overlay.style.display = 'block';
      }, 10);
    }
    this.trayElTarget.classList.remove('d-none');
  };

  showSubLevel = (subTrigger) => {
    let { menu } = subTrigger.dataset;
    const { target } = subTrigger.dataset;
    menu = JSON.parse(menu);
    this.subMenuContentsTarget.innerHTML = ''; // clear out prev content

    const subMenu = document.getElementById(target);
    const subMenuContents = subMenu.childNodes;
    subMenuContents.forEach((content) => {
      const copy = content.cloneNode(true);
      this.subMenuContentsTarget.appendChild(copy);
    });

    if (menu.topLevel) {
      this.subMenuTitleTarget.innerHTML = `${menu.title} | <small>${menu.topLevel}</small>`;
    } else {
      this.subMenuTitleTarget.innerHTML = menu.title;
    }

    // this.subMenuContentsTarget.appendChild(subMenuContents);

    this.subLevelTrayMenuTarget.classList.remove('d-none');
    this.topLevelTrayNavTarget.classList.add('d-none');
  };

  toggleElShow = () => (this.show ? this.hideEl() : this.showEl());

  closeOnOutsideClick(event) {
    if (!this.show) return;
    if (this.element.contains(event.target)) return;
    this.hideEl();
  }
}
