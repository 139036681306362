/* eslint-disable consistent-return */
import initializeDatepickers from '../../lib/datepickers';
import { onDropdownClose } from '../selectize_helpers';
import computeDisciplines from '../credentials-disciplines';
import DiagnosisCodes from './diagnosisCodes';

class AddFields {
  // This executes when the function is instantiated.
  constructor(diagnosisCodeManager) {
    this.diagnosisCodesManager = diagnosisCodeManager;
    this.links = document.querySelectorAll('.add_fields');
    this.parsedHTML = '';
    this.iterateLinks();
  }

  iterateLinks() {
    // If there are no links on the page, stop the function from executing.
    if (this.links.length === 0) return;
    // Loop over each link on the page. A page could have multiple nested forms.
    this.links.forEach((link) => {
      const target = link.getAttribute('data-target');
      const relatedField = document.querySelector(`[data-fields="${target}"]`);
      if (relatedField) {
        relatedField.setAttribute('data-nested', 'true');
        relatedField.id = `${target}_nested_field_container`;
      }
      // We want to set the EventListener for new elements added
      // to the DOM but not for elements already in the DOM
      if (link.dataset.addLinksListener !== 'set') {
        link.addEventListener('click', (e) => {
          this.handleClick(link, e);
        });
      }

      link.dataset.addLinksListener = 'set';
    });
  }

  appendFieldsWithoutUniqueId(link, target, newFieldsHtml) {
    // Add the new markup to the form if there are fields to add.
    if (link.closest('.nested-fields')) {
      // For cases where there is a nested form inside a nested form and there
      // are multiple items in the first nested form
      if (link.closest('.nested-fields').querySelector(`[data-fields='${target}']`)) {
        link.closest('.nested-fields').querySelector(`[data-fields='${target}']`).append(newFieldsHtml);
      } else {
        document.querySelector(`[data-fields='${target}']`).append(newFieldsHtml);
      }
    } else if (document.querySelector(`[data-fields='${target}']`)) {
      document.querySelector(`[data-fields='${target}']`).append(newFieldsHtml);
    } else {
      $(link).parent().find('.nested-wrapper').append(newFieldsHtml);
    }
  }

  handleClick(link, e) {
    // Run AddFields again for any new elements added to the DOM
    setTimeout(() => {
      // eslint-disable-next-line no-new
      new AddFields(this.diagnosisCodesManager);
    }, 100);

    initializeDatepickers();

    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return;
    // Prevent the browser from following the URL.
    e.preventDefault();
    // Save a unique timestamp to ensure the key of the associated array is unique.
    const time = new Date().getTime();
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
    const linkId = link.dataset.id;
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    const regexp = linkId ? new RegExp(linkId, 'g') : null;
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    const newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;

    if (newFields) {
      const preservePlaceholder = (placeholderSelectize) => {
        const placeholder = placeholderSelectize.$input[0].getAttribute('placeholder');
        if (placeholder) {
          placeholderSelectize.$control_input[0].placeholder = placeholder;
        }
      };

      const newFieldsHtml = this.parseHTMLResponse(newFields);
      const selectize = newFieldsHtml.querySelectorAll('.select.filterable');
      const selectizeTreatability = newFieldsHtml.querySelectorAll('.select.treatability');
      const selectizeText = newFieldsHtml.querySelectorAll('.select.filterable-text');
      const selectizeDiagnosisCode = newFieldsHtml.querySelectorAll('.select.diagnosis-code-select');
      const selectizeNPI = newFieldsHtml.querySelectorAll('.select.npi-select');
      const selectizeGoal = newFieldsHtml.querySelectorAll('.select.goal-select');
      const selectizeWord = newFieldsHtml.querySelectorAll('.select.word-select');
      const selectizeWordGroup = newFieldsHtml.querySelectorAll('.select.word-group-select');
      const selectizeActivityList = newFieldsHtml.querySelectorAll('.select.activity-list-select');
      const selectizeActivityItem = newFieldsHtml.querySelectorAll('.select.activity-item-select');
      const selectizeResource = newFieldsHtml.querySelectorAll('.select.resource-select');
      const selectizeCredentials = newFieldsHtml.querySelectorAll('.select.credential-select');
      const selectizeMinimalPairs = newFieldsHtml.querySelectorAll('.select.minimal-pair-select');
      const selectizeSchool = newFieldsHtml.querySelectorAll('.select.school-select');
      const selectizeDistrict = newFieldsHtml.querySelectorAll('.select.district-select');
      const selectizeReferenceLink = newFieldsHtml.querySelectorAll('.select.reference-link-select');
      const patientForm = document.getElementById('patient_form');
      const billingSequence = newFieldsHtml.querySelectorAll('.form-control.billing-sequence');
      const increaseBillingSequence = link.id === 'add-dynamic-payment-method-link';
      if (increaseBillingSequence && billingSequence) {
        // Only run this on the "Add payment method" link click, not any others
        const lastSequence = parseInt($('.billing-sequence:visible').last().val(), 10) || 0;
        const newSequence = lastSequence + 1;
        setTimeout(() => {
          $('.billing-sequence:visible').last().val(newSequence);
        }, 100);
      }

      // Check if we will insert somewhere else
      const target = link.getAttribute('data-target');

      // Toggle button for has-one-relationship
      if (link.classList.contains('has-one-relationship')) {
        link.classList.toggle('d-none');
      }
      // Add the new markup to the form if there are fields to add.
      const { uniqueIdentifier } = link.dataset;

      if (uniqueIdentifier) {
        const targetContainer = document.querySelector(`[data-container-for='${uniqueIdentifier}']`);
        if (targetContainer) {
          targetContainer.append(newFieldsHtml);
        } else {
          this.appendFieldsWithoutUniqueId(link, target, newFieldsHtml);
        }
      } else {
        this.appendFieldsWithoutUniqueId(link, target, newFieldsHtml);
      }

      const dataElement = document.querySelector(`[data-fields='${target}']`);

      // STOP DELETE OPTION 2: START
      // We want to visually hide our delete item text if it's the first link
      // and our links contain the no_last_delete class.
      // In the removeFields.js file we disable the first links functionality.
      if (dataElement) {
        const removeLinks = dataElement.querySelectorAll('.remove_fields.no_last_delete');

        if (removeLinks.length > 0) {
          removeLinks[0].textContent = '';
        }
      }
      // STOP DELETE OPTION 2: START

      if (patientForm) {
        const patientFormValidationAlert = document.getElementById('patient_form_validation_error');

        Array.from(patientForm.elements).forEach((element) => {
          element.addEventListener(
            'invalid',
            (event) => {
              let tabToDisplay = event.target.closest('div.tab-pane').dataset.name;
              if (tabToDisplay === undefined) {
                tabToDisplay = event.target.closest('div.modal').dataset.name;
              }
              patientFormValidationAlert.innerHTML = `There is a validation error with one of the fields on the "${tabToDisplay}" tab.`;
              patientFormValidationAlert.classList.remove('hide');
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            },
            true
          );
        });
      }
      // Selectize
      if (selectize) {
        $(selectize).selectize({
          create: false,
          onDropdownClose,
        });
      }

      if (selectizeText) {
        $(selectizeText).selectize({
          create: false,
          sortField: 'text',
          onDropdownClose,
        });
      }

      if (selectizeTreatability) {
        $(selectizeTreatability).selectize({
          create: false,
          sortField: 'id',
          onDropdownClose,
          render: {
            option(data, escape) {
              return `<div style="padding: 7px;">${escape(data.text)}<br><small style="color:gray;">${escape(
                data.description
              )}</small></div>`; // https://stackoverflow.com/a/36514076
            },
          },
        });
      }

      if (selectizeDiagnosisCode) {
        this.diagnosisCodesManager.addNewDiagnosisSelectComponent(selectizeDiagnosisCode);
      }

      if (selectizeNPI) {
        $(selectizeNPI).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          maxItems: 1,
          placeholder: 'Start typing to search for a provider...',
          options: [],
          preload: true,
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query) return callback();
            $.ajax({
              url: '/api/v2/npi-search',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success: (res) => {
                callback(res);
              },
            });
          },
          onChange(value) {
            const select = this.$input[0];
            $.ajax({
              url: '/api/v2/npi-search',
              type: 'GET',
              dataType: 'json',
              data: {
                q: value,
              },
              error(err) {
                console.log(err);
              },
              success: (res) => {
                if (!res || !res[0]) return;
                const result = res[0];

                const delineationElement = select.closest('div').querySelector('.addressAttributeDelineation');
                let addressAttributeDelineation;

                if (delineationElement) {
                  addressAttributeDelineation = delineationElement.dataset.delineation;
                }

                if (result.first_name)
                  select.closest('div').querySelector('.provider_first_name').value = result.first_name;
                if (result.last_name)
                  select.closest('div').querySelector('.provider_last_name').value = result.last_name;
                if (result.middle_name)
                  select.closest('div').querySelector('.provider_middle_name').value = result.middle_name;
                if (result.organization_name)
                  select.closest('div').querySelector('.provider_organization_name').value = result.organization_name;
                if (result.suffix) select.closest('div').querySelector('.provider_suffix').value = result.suffix;
                if (result.active) select.closest('div').querySelector('.provider_active').value = result.active;
                if (result.primary_taxonomy_name)
                  select.closest('div').querySelector('.provider_primary_taxonomy_name').value =
                    result.primary_taxonomy_name;
                if (result.primary_taxonomy_number)
                  select.closest('div').querySelector('.provider_primary_taxonomy_number').value =
                    result.primary_taxonomy_number;

                // do not set address attributes if there is no address attributes delineator
                if (!addressAttributeDelineation) {
                  return;
                }

                const states = {
                  AL: '792cb3da-b81d-4b84-9fe2-5eb2e8ba2fe1',
                  AK: '9761b2a2-64e3-44b7-ade7-8048a005b464',
                  AZ: '5a2065a2-ff3c-44a9-9700-27478430ca5a',
                  AR: '79a51f67-061b-4ab8-bf0e-89803c848190',
                  CA: 'f8304174-c9d1-426d-9fe8-ed8b8ccc111b',
                  CO: 'f8a476d8-f08c-4edf-a899-17ed68012611',
                  CT: '7bacf4b6-0333-4079-81b3-4a132e533cac',
                  DE: '3020b27e-e1ee-4e00-8ddc-632c15e174b0',
                  FL: '31aab059-4059-468e-8093-3d43ddf784f6',
                  GA: '35c4d00d-12c4-454d-b07c-0ae6fa3cf07e',
                  HI: '20b70c99-1e4d-4c31-b080-2eee352b25d4',
                  ID: '7dc74aa6-7dba-41e4-81e4-00e70dc276f2',
                  IL: 'e9520da2-4db6-4394-9267-42d36ac9d414',
                  IN: '5fffbfda-9a70-41ce-adef-6744af74bd33',
                  IA: '9ca6d9ee-f97c-4ae4-87c7-8c48406338ab',
                  KS: '86439c19-917e-43fa-820c-ca275e10ab91',
                  KY: 'b3724b8d-2661-4d7b-9cba-efb79b13a3c5',
                  LA: '4d1e896f-ffbd-4e58-ae27-04e9a61b98e9',
                  ME: '22a7dc8e-b87d-4992-b6c7-4cd4f3fe351f',
                  MD: 'da24b130-4488-4c33-a6e4-c7f1eecec046',
                  MA: 'edd0adb7-cbce-486d-bff7-b9022b784ca0',
                  MI: 'aeda968a-ddbb-402a-9553-6f7625b78d60',
                  MN: 'df15f16d-0c20-45ae-b9ba-61caa5793599',
                  MS: 'dd164fcb-7e1f-4f60-ad09-a82cff9c8c83',
                  MO: '74926e67-b6e0-4bf6-936f-fbfb901a3628',
                  MT: 'cb15ecc7-e6bc-4cec-abce-ed9c67f20132',
                  NE: 'fada5e0c-d1fc-4903-b692-656e1ff67e90',
                  NV: 'b2084b65-cf73-4dd6-aff0-9c3e04af758c',
                  NH: '8ea0d008-37b7-4f0a-ac0e-f9fd9dbc4e9b',
                  NJ: 'f59d589f-1a76-43c6-b5cb-33e1b633c666',
                  NM: 'fd639e11-14f2-4596-b771-ade4fe42641f',
                  NY: 'cc87d550-2fff-4eb4-ada0-1ee7928f7ce9',
                  NC: 'bb8e1276-f348-48bb-af1d-19e670289706',
                  ND: '75e3b344-c84a-4087-ae05-15556b5188ba',
                  OH: 'c97f229b-5ea2-4e72-94a0-b747863ed408',
                  OK: '336758ff-ad13-41a4-80c2-7b812e6e34af',
                  OR: 'f42355d8-1282-4241-b063-5d77ebe2d682',
                  PA: '9e4547c7-be31-4ebe-8471-75647fa2e1cd',
                  RI: '07cedcb1-71a3-4653-9f88-03e5f4e34632',
                  SC: 'ada5b0f2-4e86-4b28-8a03-b4d954fe40f2',
                  SD: '62e2edb0-5b96-41b8-a75f-5294c9fde059',
                  TN: '3e9e112c-3954-4001-b180-aac3a71a334c',
                  TX: '45c2200c-3d40-4d59-886d-69f95863bb93',
                  UT: '693d1508-dea8-4227-b168-0f343390bb5a',
                  VT: 'e4aba46b-6bf3-498f-b9fc-4d0156b3630a',
                  VA: 'cd92b344-01ce-44ed-b27d-dc9b2dc53387',
                  WA: '8417da33-6057-4607-8770-d3dc46acfd62',
                  WV: 'a188df66-9d72-492e-a5d3-61af7c3b7b03',
                  WI: '1a9a1b72-229f-433d-8ae1-c0aae33845b2',
                  WY: '24792adb-2104-470e-b007-89941e449edc',
                  DC: '2d568ff0-0456-48c8-9ff4-c04fb8836185',
                  PR: '80ceb63b-928d-4112-9e12-67f9388c17f4',
                  AS: 'f4a3b53d-dd70-41d3-96f8-5fb4f269c492',
                  GU: '17297d90-6e71-437b-ad5c-7cdeb6fa0d59',
                  VI: 'c09bb695-c2f2-4db7-b4b1-273eba209e5b',
                  FM: '5da2e734-c0b8-4858-b6dc-3a5b6508c8a1',
                  MH: '600bd931-29e6-4274-b356-c230390a49a3',
                  MP: 'c6e554e0-4697-4e0e-8151-3c428456605c',
                  PW: '828066ee-7ae4-4176-9087-2e752434998f',
                };

                const addressTypes = {
                  'LOCATION AND MAILING': 'f6749b20-b2e5-4992-bf11-6e5585b5df70',
                  LOCATION: '319705a9-f58e-4da2-9dd7-dacdfb7a1e42',
                  MAILING: 'ea876400-61b4-4bb9-a37e-b6a2171d88ab',
                };

                const phoneNumberTypes = {
                  telephone_number: '80e9a6e6-14b5-4bff-9b48-bad20308eef6',
                  fax_number: '31053637-ac84-4f96-8844-3bc829f3a251',
                };

                const fields = [
                  'address_1',
                  'address_2',
                  'address_purpose',
                  'address_type',
                  'city',
                  'country_code',
                  'country_name',
                  'fax_number',
                  'postal_code',
                  'state',
                  'telephone_number',
                ];

                const mapFields = {
                  address_1: 'address',
                  address_2: 'address_2',
                  // purpose is what we call addresstype
                  address_purpose: 'address_type_id',
                  address_type: '',
                  city: 'city',
                  country_code: 'country_code',
                  country_name: 'country_name',
                  fax_number: 'fax_number',
                  postal_code: 'zip_code',
                  state: 'state_id',
                  telephone_number: 'telephone_number',
                };

                if (result.first_name)
                  select.closest('div').querySelector('.provider_first_name').value = result.first_name;
                if (result.last_name)
                  select.closest('div').querySelector('.provider_last_name').value = result.last_name;
                if (result.middle_name)
                  select.closest('div').querySelector('.provider_middle_name').value = result.middle_name;
                if (result.organization_name)
                  select.closest('div').querySelector('.provider_organization_name').value = result.organization_name;
                if (result.suffix) select.closest('div').querySelector('.provider_suffix').value = result.suffix;
                if (result.active) select.closest('div').querySelector('.provider_active').value = result.active;
                if (result.primary_taxonomy_name)
                  select.closest('div').querySelector('.provider_primary_taxonomy_name').value =
                    result.primary_taxonomy_name;
                if (result.primary_taxonomy_number)
                  select.closest('div').querySelector('.provider_primary_taxonomy_number').value =
                    result.primary_taxonomy_number;

                for (let index = 0; index < result.addresses.length; index++) {
                  const address = result.addresses[index];

                  // set phone numbers(telephone and fax)
                  const workPhoneElement = document.getElementById(
                    `${addressAttributeDelineation}_${index}_phone_numbers_attributes_0_number`
                  );
                  const workPhoneTypeElement = document.getElementById(
                    `${addressAttributeDelineation}_${index}_phone_numbers_attributes_0_phone_number_type_id`
                  );
                  const workFaxElement = document.getElementById(
                    `${addressAttributeDelineation}_${index}_phone_numbers_attributes_1_number`
                  );
                  const workFaxTypeElement = document.getElementById(
                    `${addressAttributeDelineation}_${index}_phone_numbers_attributes_1_phone_number_type_id`
                  );

                  if (workPhoneElement && workPhoneTypeElement && address.telephone_number) {
                    workPhoneElement.value = address.telephone_number;
                    workPhoneTypeElement.value = phoneNumberTypes.telephone_number;
                  }

                  if (workFaxElement && workFaxTypeElement && address.fax_number) {
                    workFaxElement.value = address.fax_number;
                    workFaxTypeElement.value = phoneNumberTypes.fax_number;
                  }

                  // set other address fields
                  for (let idx = 0; idx < fields.length; idx++) {
                    const field = fields[idx];
                    const mappedField = mapFields[field];
                    let fieldValue = address[field];
                    const id = `${addressAttributeDelineation}_${index}_${mappedField}`;
                    const fieldElement = document.getElementById(id);
                    if (field === 'address_purpose') {
                      fieldValue = addressTypes[fieldValue];
                    } else if (field === 'state') {
                      fieldValue = states[fieldValue];
                    } else if (field === 'postal_code') {
                      fieldValue =
                        fieldValue && fieldValue.length > 5
                          ? `${fieldValue.slice(0, 5)}-${fieldValue.slice(5)}`
                          : fieldValue;
                    }

                    if (fieldValue && fieldElement) fieldElement.value = fieldValue;
                  }
                }
              },
            });
          },
          onInitialize() {
            preservePlaceholder(this);
          },
        });
      }

      if (selectizeDistrict) {
        $(selectizeDistrict).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          maxItems: 1,
          placeholder: 'Start typing to search for a district...',
          options: [],
          preload: true,
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query) return callback();
            $.ajax({
              url: '/api/v2/districts',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
          onChange(value) {
            const select = this.$input[0];
            const option = select.options[0];
            option.value = value;
            select.value = value;
          },
          onInitialize() {
            preservePlaceholder(this);
            const select = this.$input[0].dataset;
            $.ajax({
              url: '/api/v2/districts',
              type: 'GET',
              dataType: 'json',
              data: {
                q: select.name,
              },
              error(err) {
                console.log(err);
              },
              success: (res) => {
                this.addOption(res);
                const selectedItems = [];
                selectedItems.push(select.id);
                this.addItem(selectedItems);
              },
            });
          },
        });
      }

      if (selectizeSchool) {
        $(selectizeSchool).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          maxItems: 1,
          placeholder: 'Start typing to search for a school...',
          options: [],
          preload: true,
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query) return callback();
            $.ajax({
              url: '/api/v2/schools',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
          onChange(value) {
            const select = this.$input[0];
            const option = select.options[0];
            option.value = value;
            select.value = value;
          },
          onInitialize() {
            preservePlaceholder(this);
            const select = this.$input[0].dataset;
            $.ajax({
              url: '/api/v2/schools',
              type: 'GET',
              dataType: 'json',
              data: {
                q: select.name,
              },
              error(err) {
                console.log(err);
              },
              success: (res) => {
                this.addOption(res);
                const selectedItems = [];
                selectedItems.push(select.id);
                this.addItem(selectedItems);
              },
            });
          },
        });
      }

      if (selectizeReferenceLink) {
        $(selectizeReferenceLink).selectize({
          valueField: 'id',
          labelField: 'title',
          searchField: 'title',
          dataAttr: 'description',
          placeholder: 'Start typing to search for a reference link...',
          options: [],
          create: false,
          onDropdownClose,
          render: {
            item(item, escape) {
              if (item.author !== null && item.author !== undefined) {
                return `<div class="p-1">${item.title} <small>(${item.author})</small></div>`;
              }
              return `<div class="p-1">${item.title}</div>`;
            },
            option(item, escape) {
              if (item.author !== null && item.author !== undefined) {
                return `<div class="p-1">${item.title} <small>(${item.author})</small></div>`;
              }
              return `<div class="p-1">${item.title}</div>`;
            },
          },
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/reference-links',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
          onChange(value) {
            const select = this.$input[0];
            const option = select.options[0];
            option.value = value;
            select.value = value;
            const data = this.options[value];
            if (data && data.description)
              select.closest('div').parentNode.querySelector('.description-field').value = data.description;
          },
          onInitialize() {
            preservePlaceholder(this);
            const select = this.$input[0].dataset;
            $.ajax({
              url: '/api/v2/reference-links',
              type: 'GET',
              dataType: 'json',
              data: {
                q: select.name,
              },
              error(err) {
                console.log(err);
              },
              success: (res) => {
                this.addOption(res);
                const selectedItems = [];
                selectedItems.push(select.id);
                this.addItem(selectedItems);
              },
            });
          },
        });
      }

      if (selectizeGoal) {
        $(selectizeGoal).selectize({
          valueField: 'id',
          labelField: 'goal',
          searchField: 'goal',
          placeholder: 'Start typing to search for a goal...',
          options: [],
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/goals',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
        });
      }

      if (selectizeWord) {
        $(selectizeWord).selectize({
          valueField: 'id',
          labelField: 'word',
          searchField: 'word',
          placeholder: 'Start typing to search for a word...',
          options: [],
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/words',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
        });
      }

      if (selectizeWordGroup) {
        $(selectizeWordGroup).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          placeholder: 'Start typing to search for a word group...',
          options: [],
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/word-groups',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
        });
      }

      if (selectizeActivityItem) {
        $(selectizeActivityItem).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          placeholder: 'Start typing to search for an item...',
          options: [],
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/activity-items',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
        });
      }

      if (selectizeActivityList) {
        $(selectizeActivityList).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          placeholder: 'Start typing to search for an activity list...',
          options: [],
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/activity-lists',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
        });
      }

      if (selectizeResource) {
        $(selectizeResource).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          placeholder: 'Start typing to search for a PDF resource...',
          options: [],
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/resources',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
        });
      }

      if (selectizeMinimalPairs) {
        $(selectizeMinimalPairs).selectize({
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          placeholder: 'Start typing to search for a minimal pair...',
          options: [],
          create: false,
          onDropdownClose,
          load(query, callback) {
            if (!query.length) return callback();
            $.ajax({
              url: '/api/v2/minimal-pairs',
              type: 'GET',
              dataType: 'json',
              data: {
                q: query,
              },
              error() {
                callback();
              },
              success(res) {
                callback(res);
              },
            });
          },
        });
      }

      const credentialSelectHandler = () => {
        const credentialFields = document.querySelectorAll('.select.credential-select');
        const credentialFieldsValues = Array.from(credentialFields).map((field) => field.value);
        computeDisciplines(credentialFieldsValues);
      };

      if (selectizeCredentials) {
        $(selectizeCredentials)
          .selectize({
            create: false,
            sortField: 'id',
            onDropdownClose,
          })
          .on('change', credentialSelectHandler);
      }

      // Fire an event to tell other files. Useful when you need to re-run a query select all.
      const fieldAdded = new Event('fieldAdded');
      document.dispatchEvent(fieldAdded);

      // Show save button
      $('.nested-field-save').show();
    }
  }

  getNewIndexFromTimeString() {
    const now = new Date();
    return now.getHours().toString() + now.getMinutes().toString() + now.getSeconds().toString();
  }

  // From: https://stackoverflow.com/a/42658543/445724
  // using .innerHTML= is risky. Instead we need to convert the HTML received
  // into elements, then append them.
  // It's wrapped in a <template> tag to avoid invalid (e.g. a block starting with <tr>)
  // being mutated inappropriately.
  parseHTMLResponse(HTML) {
    // If the addFields is done through template injection then there will be a number of
    // {index} strings that need to be replaced. There represent the index of form fields names eg:
    // evaluation[some_time][{index}][the_field]
    const newFromFieldsIndex = this.getNewIndexFromTimeString();
    HTML = HTML.replace(/{index}/g, newFromFieldsIndex);

    const parser = new DOMParser();
    const responseDocument = parser.parseFromString(`<template>${HTML}</template>`, 'text/html');
    this.parsedHTML = responseDocument.head.firstElementChild.content;
    return this.parsedHTML;
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new AddFields(new DiagnosisCodes()));

export default AddFields;
