import consumer from './consumer';
import VisualScheduleChannelCreator from '../visual-schedules/lib/channel_creator';
import log from '../lib/logger';

export default class RoomSessionChannel {
  constructor(options) {
    this.store = options.store; // Vuex store
    this.roomsSessionId = options.roomsSessionId;
    this.viewerId = options.viewerId;
    this.channel = null;
    this.isResolved = null;
  }

  init = () =>
    new Promise((resolve) => {
      this.isResolved = resolve;
      this.createSubscription();
    });

  createSubscription = () => {
    const { roomsSessionId, viewerId, store, isResolved } = this;
    this.channel = consumer.subscriptions.create(
      {
        channel: 'RoomsSessionChannel',
        id: roomsSessionId,
        user_id: viewerId,
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
          isResolved();
          log.info('Room Session Channel connected');
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          log.info('Room Session Channel disconnected');
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel
          log.debug('Room Session Channel data received', data);
          // Ignore if this message is intended for only one participant
          if (data.for_viewer !== undefined && data.for_viewer !== null && viewerId !== data.for_viewer) return;

          const owner = store.state.visitor.id === store.state.ownerId;
          if (data.room_started === true) {
            store.commit('setRoomSessionStarted', true);
          } else if (data.meeting_mode !== undefined) {
            store.commit('admin/toggleMeeting', data.meeting_mode);
          } else if (data.participant_connected_to_session !== undefined) {
            if (owner) {
              // At this point we only want to update `primaryTherapistInSession`, because the participant hasn't yet
              // joined the session.
              store.state.roomSessionChannel.perform('therapist_entered_session', { syncState: false });
            }
            this.syncSupervisorState(data);
          } else if (data.participant_entered_session !== undefined) {
            if (owner) {
              // When the participant joins the session, we want to synchronize the state of the therapist with the
              // connected participant.
              this.sendCurrentSessionState(data.participant_entered_session);
            }
            store.dispatch('handleRoomsSessionVisitorArrival', { visitorId: data.participant_entered_session });
          } else if (data.therapist_entered_session !== undefined) {
            // When the participant is already there in the session and the therapist somehow gets disconnected or their
            // browser reloads and then joins the session again, we want to update the therapist's state to the
            // already existing participants.
            const { activeVisitors } = store.state;
            if (owner && data.sync_state && activeVisitors.length > 0) {
              activeVisitors.forEach((visitor) => {
                this.sendCurrentSessionState(visitor.id);
              });
            }
            store.dispatch('setTherapistSessionActiveState', true);
            this.syncSupervisorState(data);
          } else if (data.therapist_left_session !== undefined) {
            store.dispatch('setTherapistSessionActiveState', false);
          } else if (data.sync_whiteboard_for !== undefined) {
            if (owner) this.syncWhiteBoardCanvasObjects(data.sync_whiteboard_for);
          } else if (data.sync_resources_for !== undefined) {
            if (owner) this.syncResourceCanvasObjects(data.sync_resources_for);
          } else if (data.toast_errors !== undefined) {
            store.commit('admin/setToastErrors', data.toast_errors);
          } else if (data.action === 'set_demoing_participant') {
            const { id, name } = data;
            store.commit('demoMode/setDemoingParticipant', { id, name });
          } else if (data.whiteboard_status !== undefined) {
            store.commit('layout/setWhiteBoardMode', data.whiteboard_status);
          } else if (data.canvas_object !== undefined) {
            store.dispatch('canvas/addCanvasObject', data.canvas_object.object);
          } else if (data.canvas_object_update !== undefined) {
            // Do not update artists' state, it will mess up mouse position
            if (store.state.visitor.id === data.canvas_object_update.object.artistId) return;
            store.dispatch('canvas/updateCanvasObject', data.canvas_object_update.object);
          } else if (data.canvas_clear === true) {
            store.dispatch('canvas/clearCanvasObjects', data.canvasId);
          } else if (data.canvas_clear_object !== undefined) {
            store.commit('canvas/removeCanvasObject', data.canvas_clear_object);
          } else if (data.resource_canvas_lock_active !== undefined) {
            store.dispatch('resource/handleResourceCanvasLocked', {
              selectableState: data.resource_canvas_lock_active,
              canvasId: 'resource-canvas',
            });
          } else if (data.screenshare_status !== undefined && !owner) {
            store.commit('layout/setScreenshareMode', data.screenshare_status);
          } else if (data.resource_status !== undefined) {
            store.commit('layout/setResourceMode', data.resource_status);
            if (!data.resource_status) store.commit('canvas/resetCanvasPosition');
          } else if (data.resource_selection !== undefined) {
            store.dispatch('resource/handleResourceSelection', data);
          } else if (data.canvas_position !== undefined) {
            store.commit('canvas/updateCanvasPosition', { canvasId: data.canvasId, num: data.canvas_position });
          } else if (data.click_beacon_active !== undefined) {
            store.commit('sharingMedia/setClickBeaconActive', data.click_beacon_active);
          } else if (data.click_beacon_coordinates !== undefined) {
            const { participant } = data.click_beacon_coordinates;
            const { visitor, ownerId } = store.state;

            // If click beacon creator is current user, return
            if (participant.id === visitor.id) return;
            // If click beacon creator is not therapist, and if click beacon creator is not demoing, and if
            // current user is not demoing, and if current user is not owner, return
            if (
              participant.id !== ownerId &&
              !store.getters['demoMode/isDemoing'](participant.id) &&
              !store.getters['demoMode/isDemoing'](visitor.id) &&
              !owner
            )
              return;

            store.dispatch('sharingMedia/handleClickBeacon', data.click_beacon_coordinates);
          } else if (data.resource_interact_mode !== undefined) {
            store.commit('resource/setInteractMode', data.resource_interact_mode);
          } else if (data.settings !== undefined && owner) {
            if (data.settings.user) store.commit('settings/storeUserSettings', data.settings.user);
            if (data.settings.patient) store.commit('settings/updateSinglePatientSetting', data.settings.patient);
            if (data.settings.patients) store.commit('settings/storeAllPatientSettings', data.settings.patients);
            if (data.settings.organization)
              store.commit('settings/setOrganizationSettings', data.settings.organization);
          } else if (
            data.settings !== undefined &&
            data.settings.patient &&
            store.state.visitor.id === data.settings.patient.id
          ) {
            store.commit('settings/storePatientSettings', data.settings.patient);
            if (data.settings.organization)
              store.commit('settings/setOrganizationSettings', data.settings.organization);
          } else if (data.action === 'visual_schedule_initialized') {
            if (store.state.visitor.id !== data.id) {
              new VisualScheduleChannelCreator({ store, sessionId: data.sessionId }).createChannel();
              store.commit('setVisualSchedule', data.visualSchedule);
              store.commit('layout/toggleVisualSchedule', true);
              store.commit('setUserId', store.state.visitor.id);
              store.dispatch('startVisualScheduleSharing', { streamerId: data.streamerId });
            }
          } else if (data.action === 'visual_schedule_hidden') {
            if (store.state.visitor.id !== data.id) {
              store.commit('layout/toggleVisualSchedule', false);
              store.dispatch('stopVisualScheduleSharing');
            }
          } else if (data.action === 'room_timer') {
            if (data.duration !== undefined) {
              store.commit('roomTimer/setDurationSecs', data.duration);
            }
            if (data.timePassed !== undefined) {
              store.commit('roomTimer/setTimePassed', data.timePassed);
            }
            if (store.state.visitor.id !== data.id) {
              if (data.showTimer !== undefined) {
                store.commit('layout/setRoomTimerVisibility', data.showTimer);
                store.commit('roomTimer/setVisibleToParticipants', data.showTimer);
              }
              if (data.startTimer) {
                store.commit('roomTimer/play');
              } else if (data.startTimer !== undefined) store.commit('roomTimer/pause');
              if (data.clear) {
                store.commit('roomTimer/clear');
              }
              if (data.playSound !== undefined) {
                store.commit('roomTimer/setPlaySound', data.playSound);
              }
            }
          } else if (data.action === 'stream_management') {
            switch (data.type) {
              case 'mic':
                store.dispatch('janus/handleRemoteMic', data);
                break;

              case 'video':
                store.dispatch('janus/handleRemoteVideo', data);
                break;

              case 'remove':
                store.dispatch('janus/handleRemoteRemoval', data);
                break;
              default:
                break;
            }
          } else if (data.action === 'participant_admin') {
            let action = 'layout/handleRemoteVisibleArea';
            let payload;
            switch (data.type) {
              case 'whiteboard_active':
                action = 'sharingMedia/handleRemoteWhiteboardLock';
                payload = { data, canvasId: 'whiteboard-canvas' };
                break;
              case 'secondary_media_visible':
                payload = { data, mutation: 'setSecondaryMediaVisible', prop: 'secondaryMediaHidden' };
                break;
              case 'sidebar_active':
                payload = { data, mutation: 'toggleToolbar', prop: 'toolBarHidden' };
                break;
              case 'screen_cleared':
                payload = { data, mutation: 'toggleAllHidden', prop: 'screenCleared' };
                break;
              case 'participant_navigator_visible':
                payload = { data, mutation: 'setParticipantNavigatorVisible', prop: 'participantNavigatorHidden' };
                break;
              default:
                break;
            }
            store.dispatch(action, payload);
          } else if (data.action === 'spotlight_participant') {
            store.commit('layout/setSpotlightedParticipant', data.id);
          } else if (data.action === 'sync_supervisor_settings') {
            // We do not want to update the supervisor's state.
            // It causes double re-render of the component.
            if (store.state.visitor.id === data.visitor_id) return;
            store.commit('setSupervisorVisibility', data.supervisor_is_visible);
          }
        },

        sendCurrentSessionState(targetUserId) {
          const { state, getters } = store;
          const { activeSessionId, roomSessionChannel, layout, visualSchedule, sharingMedia, demoMode } = state;
          const { whiteBoardMode, resourceMode } = layout;
          // Therapist is active, but we don't want to synchronize the settings at this point because we already handle
          // the update at the parent level. Synchronizing here will result in an infinite loop.
          roomSessionChannel.perform('therapist_entered_session', { syncState: false });
          // Whiteboard
          if (whiteBoardMode) {
            roomSessionChannel.perform('start_whiteboard', { for_viewer: targetUserId });
          } else {
            roomSessionChannel.perform('end_whiteboard', { for_viewer: targetUserId });
          }
          // Resource library
          if (resourceMode) {
            roomSessionChannel.perform('start_resource', { for_viewer: targetUserId });
            roomSessionChannel.perform('select_resource', {
              id: state.resource.sharingResourceId,
              position: state.canvas.canvasPositions['resource-canvas'],
              for_viewer: targetUserId,
            });
            roomSessionChannel.perform('resource_interact_mode', {
              mode: state.resource.interactMode,
              for_viewer: targetUserId,
            });
            roomSessionChannel.perform('click_beacon_active', {
              status: state.sharingMedia.clickBeaconActive,
              for_viewer: targetUserId,
            });
            roomSessionChannel.perform('resource_canvas_lock_active', {
              locked: state.resource.resourceDrawingLocked,
              for_viewer: targetUserId,
            });
          } else {
            roomSessionChannel.perform('end_resource', { for_viewer: targetUserId });
          }
          // Click Beacon
          if (sharingMedia.clickBeaconActive) {
            roomSessionChannel.perform('click_beacon_active', { status: true, for_viewer: targetUserId });
          } else {
            roomSessionChannel.perform('click_beacon_active', { status: false, for_viewer: targetUserId });
          }
          // Visual Schedule
          if (getters.isVisualScheduleSharing) {
            roomSessionChannel.perform('visual_schedule_initialized', {
              id: state.visitor.id,
              sessionId: activeSessionId,
              visualSchedule,
              streamerId: state.visualScheduleSharing.streamerId,
              for_viewer: targetUserId,
            });
          } else {
            roomSessionChannel.perform('visual_schedule_hidden', {
              id: state.visitor.id,
              for_viewer: targetUserId,
            });
          }
          // Room Timer
          roomSessionChannel.perform('room_timer', {
            id: state.visitor.id,
            showTimer: state.roomTimer.visibleToParticipants,
            startTimer: state.roomTimer.running,
            playSound: state.roomTimer.playSound,
            duration: state.roomTimer.duration,
            timePassed: state.roomTimer.timePassed,
            for_viewer: targetUserId,
          });
          // Demoing participant
          if (demoMode.demoingParticipant.id) {
            const { id, name } = demoMode.demoingParticipant;
            roomSessionChannel.perform('set_demoing_participant', { id, name, for_viewer: targetUserId });
          }
          // Screenshare ?
        },

        syncSupervisorState(data) {
          const { state } = store;
          const { visitor, supervisors, ownerId, supervisorIsVisible, roomSessionChannel } = state;

          const supervisorIds = Array.from(supervisors, (s) => s.userId);
          if (!supervisorIds.includes(visitor.id)) return;
          if (supervisorIds.includes(data.participant_connected_to_session)) return;

          roomSessionChannel.perform('sync_supervisor_settings', {
            visitorId: visitor.id,
            supervisorIsVisible,
            for_viewer: data.therapist_entered_session ? ownerId : data.participant_connected_to_session,
          });
        },

        syncWhiteBoardCanvasObjects(targetUserId) {
          const { state } = store;
          const { roomSessionChannel, canvas } = state;
          const wbCanvas = canvas.canvases['whiteboard-canvas'];
          if (wbCanvas) {
            // Sync all the objects
            // eslint-disable-next-line no-underscore-dangle
            wbCanvas._objects.forEach((object) => {
              let jsonObj;
              const keys = ['artistId', 'id', 'sourcePath', 'perPixelTargetFind', 'canvasId'];
              // Convert to JSON
              if (object.sourcePath) {
                // item is svg
                jsonObj = object.toDatalessObject(keys);
              } else {
                // item is drawn path
                jsonObj = object.toJSON(keys);
              }
              if (!jsonObj.canvasId) jsonObj.canvasId = 'whiteboard-canvas';
              roomSessionChannel.perform('canvas_object', { object: jsonObj, for_viewer: targetUserId });
            });
          }
        },

        syncResourceCanvasObjects(targetUserId) {
          const { state } = store;
          const { roomSessionChannel, canvas } = state;
          const resourceCanvas = canvas.canvases['resource-canvas'];
          if (resourceCanvas) {
            // Sync all the objects
            // eslint-disable-next-line no-underscore-dangle
            resourceCanvas._objects.forEach((object) => {
              let jsonObj;
              const keys = ['artistId', 'id', 'sourcePath', 'perPixelTargetFind', 'canvasId', 'pageNumber'];
              // Convert to JSON
              if (object.sourcePath) {
                // item is svg
                jsonObj = object.toDatalessObject(keys);
              } else {
                // item is drawn path
                jsonObj = object.toJSON(keys);
              }
              if (!jsonObj.canvasId) jsonObj.canvasId = 'resource-canvas';
              roomSessionChannel.perform('canvas_object', { object: jsonObj, for_viewer: targetUserId });
            });
          }
        },
      }
    );
  };
}
