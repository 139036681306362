import { nextFrame } from 'helpers/timing';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['focusable'];

  async focus() {
    await nextFrame();
    if (this.hasFocusableTarget) {
      this.focusableTarget.focus();
    }
  }
}
