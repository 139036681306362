// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import axios from 'axios';
import debounce from 'lodash.debounce';
import StrongPassword from '../helpers/strongPassword';
import '../controllers/front_pages_index';
import 'lib/tooltip';

import registerCustomElement from '../custom-elements/helpers/registerCustomElement';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../stylesheets/front_pages.scss';

require.context('../fonts/', true, /\.(eot|ttf|woff|woff2|otf)$/i);

require('@rails/ujs').start();
const Turbolinks = require('turbolinks');
require('../helpers/nested-fields/addFields');
require('../helpers/nested-fields/removeFields');
const selectize = require('selectize');
require('@rails/activestorage').start();
require('channels');
require('bootstrap');
require('../lib/autocomplete');
require('helpers/trix-editors');
require('@rails/actiontext');
require('../lib/mobile-search');

Turbolinks.start();
require('../lib/turbolinks-reloader');

// Issue: Turbolinks does not reset scroll position when a new link is clicked
// Try clicking a link the footer to reproduce.
// Using window.scrollTo(0, 0) causes the page to jump to top of the current page before loading
// the new page which is jarring.
// https://github.com/turbolinks/turbolinks/issues/181

if (document.querySelector('meta[name="csrf-token"]')) {
  axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
}

// If using a third party plugin like selectize.js that manipulates the DOM,
// unhook it from the elements it's attached to before turbolinks caches it.
document.addEventListener('turbolinks:before-cache', () => {
  $('.selectized').each(function () {
    if (this.selectize) {
      this.selectize.destroy();
    }
  });
});

(function () {
  // Tell the browser not to handle scrolling when restoring via the history or when reloading
  if ('scrollRestoration' in history) history.scrollRestoration = 'manual';

  const SCROLL_POSITION = 'scroll-position';
  const PAGE_INVALIDATED = 'page-invalidated';

  // Patch the reload method to flag that the following page load originated from the page being invalidated
  Turbolinks.BrowserAdapter.prototype.reload = function () {
    sessionStorage.setItem(PAGE_INVALIDATED, 'true');
    location.reload();
  };

  // Persist the scroll position when leaving a page
  addEventListener('beforeunload', () => {
    sessionStorage.setItem(
      SCROLL_POSITION,
      JSON.stringify({
        scrollX,
        scrollY,
        location: location.href,
      })
    );
  });

  // When a page is fully loaded:
  // 1. Get the persisted scroll position
  // 2. If the locations match and the load did not originate from a page invalidation, scroll to the persisted position
  // 3. Remove the persisted information
  addEventListener('DOMContentLoaded', (event) => {
    const scrollPosition = JSON.parse(sessionStorage.getItem(SCROLL_POSITION));

    if (shouldScroll(scrollPosition)) {
      scrollTo(scrollPosition.scrollX, scrollPosition.scrollY);
    }

    sessionStorage.removeItem(SCROLL_POSITION);
    sessionStorage.removeItem(PAGE_INVALIDATED);
  });

  function shouldScroll(scrollPosition) {
    return (
      scrollPosition &&
      scrollPosition.location === location.href &&
      !JSON.parse(sessionStorage.getItem(PAGE_INVALIDATED))
    );
  }
})();

document.addEventListener('turbolinks:load', () => {
  registerCustomElement(require.context('../custom-elements', true, /details-dialog-element\.js$/));
  registerCustomElement(require.context('../custom-elements', true, /command-palette-.*element\.js$/));

  // messages form relies on this to reupdate form when changes are made
  $('.m-select.filterable')
    .selectize({
      create: false,
      sortField: 'id',
    })
    .on('change', (e) => {
      e.target.dispatchEvent(new Event('selectize-change'));
    });

  // set up selectize filterables
  $('.select.filterable')
    .selectize({
      create: false,
      sortField: 'id',
    })
    .on('change', (e) => {
      // The below will check for an element that we would like to toggle based on an item selected.
      // The toggable element must have the below class and should have it's id set to the value
      // of the dropdown select item.
      const toggleElement = document.querySelector('.select-toggle-element');
      if (!toggleElement) return;

      const valueToMatch = toggleElement.id;

      if (valueToMatch === e.target.value) {
        toggleElement.classList.remove('d-none');

        if (toggleElement.classList.contains('add-required')) {
          toggleElement.required = true;
        }
      } else {
        toggleElement.classList.add('d-none');

        if (toggleElement.classList.contains('add-required')) {
          toggleElement.required = false;
        }
      }
    });

  // Setup multi select with selectize().
  $('.multi-select').selectize({
    create: false,
    plugins: ['remove_button'],
    searchField: ['text', 'optgroup'],
    delimiter: ',',
  });

  // mount selectize
  $('.select.filterable-text')
    .selectize({
      create: false,
      sortField: 'text',
    })
    .on('change', (e) => {
      // The below will check for an element that we would like to toggle based on an item selected.
      // The toggable element must have the below class and should have it's id set to the value
      // of the dropdown select item.
      const toggleElement = document.querySelector('.select-toggle-element');
      if (!toggleElement) return;

      const valueToMatch = toggleElement.id;

      if (valueToMatch === e.target.value) {
        toggleElement.classList.remove('d-none');

        if (toggleElement.classList.contains('add-required')) {
          toggleElement.required = true;
        }
      } else {
        toggleElement.classList.add('d-none');

        if (toggleElement.classList.contains('add-required')) {
          toggleElement.required = false;
        }
      }
    });

  if (!selectize.prototype.positionDropdownOriginal) {
    selectize.prototype.positionDropdownOriginal = selectize.prototype.positionDropdown;
    selectize.prototype.positionDropdown = function () {
      if (this.settings.dropdownDirection === 'up') {
        const { $control } = this;
        const offset = this.settings.dropdownParent === 'body' ? $control.offset() : $control.position();

        this.$dropdown.css({
          width: $control.outerWidth(),
          top: offset.top - this.$dropdown.outerHeight(),
          left: offset.left,
        });
        this.$dropdown.addClass(`direction-${this.settings.dropdownDirection}`);
        this.$control.addClass(`direction-${this.settings.dropdownDirection}`);
        this.$wrapper.addClass(`direction-${this.settings.dropdownDirection}`);
      } else {
        selectize.prototype.positionDropdownOriginal.apply(this, arguments);
      }
    };
  }

  $('.select.filterable-text-up').selectize({
    create: false,
    sortField: 'text',
    dropdownDirection: 'up',
  });

  if (document.getElementById('taxonomy-container')) {
    // https://stackoverflow.com/a/59495659/1276696
    window.pageJSLoading = true;
    import('../pages/taxonomy').then((module) => {
      const taxonomy = module.default;
      if (taxonomy !== undefined) taxonomy();
      window.pageJSLoading = false;
    }); // webpack will load this JS async
  }

  // Strong Password
  const passwordInput = document.getElementById('password_validator');
  const passConfirmInput = document.getElementById('user_password_confirmation');
  const termsAndPrivacyAgreement = document.getElementById('terms_and_privacy_agreement');
  if (passwordInput && !termsAndPrivacyAgreement) {
    // Non-sign up page (password reset, etc.)
    const sp = new StrongPassword({ container: passwordInput });
    const debouncedQuery = debounce(() => {
      sp.validate(passwordInput.value).then((valid) => {
        passwordInput.form.querySelector('input[type="submit"]').disabled = !valid;
      });
    }, 200);
    passwordInput.addEventListener('keyup', debouncedQuery);
    if (passConfirmInput) {
      passConfirmInput.addEventListener('keyup', () => {
        const valid = sp.matches(passConfirmInput.value);
        passwordInput.form.querySelector('input[type="submit"]').disabled = !valid;
      });
    }
  } else if (passwordInput && termsAndPrivacyAgreement) {
    // Sign-up page only. Check Terms and Privacy checkbox too.

    const sp = new StrongPassword({ container: passwordInput });
    const debouncedQuery = debounce(() => {
      sp.validate(passwordInput.value).then((valid) => {
        document.getElementById('sign_up_submit').dataset.passwordValid = valid;
        const signUpButton = document.getElementById('sign_up_submit');
        document.getElementById('sign_up_submit').disabled = !(
          signUpButton.dataset.agreeToTermsAndPrivacy === 'true' &&
          signUpButton.dataset.passwordValid === 'true' &&
          signUpButton.dataset.buttonEnable === 'true'
        );
      });
    }, 200);
    passwordInput.addEventListener('keyup', debouncedQuery);
    if (passConfirmInput) {
      passConfirmInput.addEventListener('keyup', () => {
        const valid = sp.matches(passConfirmInput.value);
        document.getElementById('sign_up_submit').dataset.passwordValid = valid;
        const signUpButton = document.getElementById('sign_up_submit');
        document.getElementById('sign_up_submit').disabled = !(
          signUpButton.dataset.agreeToTermsAndPrivacy === 'true' &&
          signUpButton.dataset.passwordValid === 'true' &&
          signUpButton.dataset.buttonEnable === 'true'
        );
      });
    }

    if (termsAndPrivacyAgreement) {
      termsAndPrivacyAgreement.addEventListener('change', (event) => {
        document.getElementById('sign_up_submit').dataset.agreeToTermsAndPrivacy = event.target.checked;
        const signUpButton = document.getElementById('sign_up_submit');
        document.getElementById('sign_up_submit').disabled = !(
          signUpButton.dataset.agreeToTermsAndPrivacy === 'true' &&
          signUpButton.dataset.passwordValid === 'true' &&
          signUpButton.dataset.buttonEnable === 'true'
        );
      });
    }
  }

  const expandableImage = [...document.querySelectorAll('.expand-image-r')];
  if (expandableImage.length) {
    window.pageJSLoading = true;
    import('../components/common/index').then((module) => {
      const lightBoxes = module.default;
      if (lightBoxes !== undefined) lightBoxes(expandableImage);
      window.pageJSLoading = false;
    });
  }

  const reg = document.getElementById('registration_modal');
  if (reg) {
    window.pageJSLoading = true;
    import('../registration-modal').then((module) => {
      module.default(reg);
      window.pageJSLoading = false;
    });
  }

  const sideNavCard = document.getElementById('side-navigation-card');
  if (sideNavCard) {
    window.pageJSLoading = true;
    import('../components/common/SideNavIndex').then((module) => {
      module.default(sideNavCard);
      window.pageJSLoading = false;
    });
  }

  // Remove unused parameters from URL in GET request by disabling the form fields
  $('.search-get-form').submit(() => {
    $('input').each(() => {
      if ($(this).val() === '') {
        $(this).attr('disabled', true);
      }
    });

    $('select').each(() => {
      if ($(this).val() === '' || $(this).val() === null || $(this).val() === undefined) {
        $(this).attr('disabled', true);
      }
    });

    $('button').each(() => {
      $(this).attr('disabled', true);
    });
  });
});
