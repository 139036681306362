export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function replaceCamelKeyWithSnake(objects) {
  objects.forEach((object) => {
    Object.keys(object).forEach((key) => {
      const snake = camelToSnakeCase(key);
      if (key !== snake) {
        Object.defineProperty(object, snake, Object.getOwnPropertyDescriptor(object, key));
        delete object[key];
      }
    });
  });
  return objects;
}

export function mungeAllowedParams(objects, allowed) {
  objects.forEach((object) => {
    Object.keys(object).forEach((key) => {
      if (!allowed.includes(key)) delete object[key];
    });
  });
  return objects;
}

export const nextUntil = (elem, endId) => {
  // Setup siblings array
  const siblings = [];

  // Get the next sibling element
  elem = elem.nextElementSibling;

  // As long as a sibling exists
  while (elem) {
    // Break if we match the passed HTML element id
    if (elem.getAttribute('id') === endId) {
      break;
    }

    // Otherwise, push it to the siblings array
    siblings.push(elem);

    // Get the next sibling element
    elem = elem.nextElementSibling;
  }

  return siblings;
};

export function clone(array) {
  return JSON.parse(JSON.stringify(array));
}

export const closeOnEsc = (callback) => {
  const close = (e) => {
    if (e.keyCode === 27) {
      callback();
    }
  };
  window.addEventListener('keydown', close);
  // return () => window.removeEventListener('keydown', close);
};

export function truncateString(str, num) {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
}

/**
 * @param {number} num
 * @link https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
 * @returns {string}
 */
export function ordinal(num) {
  return ['st', 'nd', 'rd'][((((num + 90) % 100) - 10) % 10) - 1] || 'th';
}

export function brandColors() {
  return {
    ambikiBlue: '#2948a0',
    ambikiSalmon: '#f18e69',
    ambikiPink: '#ef3dc0',
    ambikiTurquoise: '#3cceb7',
    ambikiYellow: '#f1b241',
  };
}
