import { closeOnEsc } from '../lib/helpers';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['popEl', 'trigger'];

  initialize() {
    this.show = false;
    this.overlay = document.getElementById('pageOverlay');
  }

  connect() {
    this.triggerTarget.addEventListener('click', () => {
      this.toggleElShow();
    });
    closeOnEsc(this.hideEl);
    // Prevent tooltip from getting stuck in visible mode
    $(this.triggerTarget).on('show.bs.tooltip', () => {
      setTimeout(() => {
        if (this.show) $(this.triggerTarget).tooltip('hide');
      }, 1);
    });
  }

  hideEl = () => {
    this.show = false;
    this.triggerTarget.classList.remove('active');
    this.popElTarget.classList.add('d-none');
    if (this.overlay) this.overlay.style.display = 'none';
  };

  showEl = () => {
    this.show = true;
    this.triggerTarget.classList.add('active');
    this.popElTarget.classList.remove('d-none');
    // Dispatch an event that the pop card is shown
    // Listen (if you need to do something when this opens)
    // for this event like data-action="pop-card-shown@window->functionName"
    const event = new CustomEvent('pop-card-shown');
    window.dispatchEvent(event);
    if (this.overlay) {
      // The delay ensures overlay shows if another tray trigger is clicked
      setTimeout(() => {
        this.overlay.style.display = 'block';
      }, 10);
    }
  };

  toggleElShow = () => (this.show ? this.hideEl() : this.showEl());

  closeOnOutsideClick(event) {
    if (!this.show) return;
    if (this.element.contains(event.target)) return;
    this.hideEl();
  }
}
