/**
 * CalendarResourcesChannel subscribes to the CalendarResourcesChannel on the server to receive real-time resource updates.
 * When a resource is received, it checks if the resource is already present in the calendar and adds it if it is new.
 */
import consumer from './consumer';
import {metaValue} from "helpers/dom-helper";
export default class CalendarResourcesChannel {
  /**
   * Initializes a new instance of CalendarResourcesChannel.
   * Subscribes to the channel and binds the received method to handle incoming resources.
   */
  constructor(element) {
    this.channel = consumer.subscriptions.create(
      {
        channel: 'CalendarResourcesChannel',
        organization_id: this.orgid,
      },
      {
        received: this.received.bind(this),
      }
    );
  }
  /**
   * Handles received resources by checking if each event is already present in the calendar.
   * Adds new resources to the calendar. Logs an error if the calendar is not defined.
   *
   * @param {Object} eventData - Contains the array of events.
   * @param {Array} eventData.events - List of event objects to process.
   */
  received(payload) {
    if (payload.cid !== this.cid) return; // Don't broadcast resources to other clients.
    if (window?.calendarSettings?.mainCalendar && window?.calendarSettings?.isAdmin) {
      for (const resource of payload.resources) {
        if (!this.calendar.getResourceById(resource.id)) {
          this.calendar.addResource(resource);
        }
      }
    }
  }
  /**
   * Getter for the calendar object.
   *
   * @return {Object|null} The calendar object from the global window, or null if not defined.
   */
  get calendar() {
    return window.calendar;
  }

  /**
   * The client id.
   * @returns {string|null}
   */
  get cid() {
    return metaValue('client-id');
  }

  /**
   * The current org id
   * @returns {string|null}
   */
  get orgid() {
    return metaValue('current-org-id')
  }
}
