import ApplicationController from './application_controller';
import { closeOnEsc } from '../lib/helpers';

/**
 *
 * This controls the navigation component use to go through landing pages.
 *
 * @controller landing-page-nav
 *
 */
export default class extends ApplicationController {
  initialize() {
    this.show = false;
    this.toggleRunning = false;
  }

  /**
   *
   * Listen's for clicks both inside the menu (Stop the event from triggering) and outside,
   * to trigger a close of the menu.
   * @function toggleMenu
   * @param {*} event
   *
   */
  connect() {
    const floatingMenu = document.getElementById('floating-menu');
    const navBtnClosed = document.getElementById('nav-button-closed');
    const navBtnOpen = document.getElementById('nav-button-open');

    closeOnEsc(closeMenu);

    window.addEventListener('click', () => {
      if (!this.show && this.toggleRunning) return;
      closeMenu();
      this.show = false;
    });
    floatingMenu.addEventListener('click', (event) => {
      event.stopPropagation();
    });

    function closeMenu() {
      floatingMenu.classList.add('d-none');
      navBtnClosed.classList.remove('d-none');
      navBtnOpen.classList.add('d-none');
    }
  }

  /**
   *
   * Shows the menu
   * @function toggleMenu
   * @param {*} event
   *
   */
  toggleMenu() {
    this.toggleRunning = true;

    const floatingMenu = document.getElementById('floating-menu');
    const navBtnClosed = document.getElementById('nav-button-closed');
    const navBtnOpen = document.getElementById('nav-button-open');

    if (floatingMenu.classList.contains('d-none')) {
      floatingMenu.classList.remove('d-none');
      navBtnClosed.classList.add('d-none');
      navBtnOpen.classList.remove('d-none');

      setTimeout(() => {
        this.show = true;
        this.toggleRunning = false;
      }, 200);
    }
  }
}
