let goalPositionTracker = 1;
let previousString = '';
let defaultValueData = '';

function setDefaultDataValue() {
  if (document.getElementById('default_value_data') !== null) {
    defaultValueData = JSON.parse(document.getElementById('default_value_data').dataset.source);
  }
}

function resetGoalFormPositions() {
  const elements = document.getElementsByName('goal[default_values][default][][position]');
  elements.forEach((el, i) => {
    el.value = i + 1;
  });
}

function getAllIndexes(arr, val) {
  const indexes = [];
  let i = -1;
  while ((i = arr.indexOf(val, i + 1)) != -1) {
    indexes.push(i);
  }
  return indexes;
}

function addObjectPronounGoalFormElement() {
  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('object-pronoun-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Object pronoun ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'object_pronoun';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = ['she', 'he', 'they'];
  const option = document.createElement('option');
  option.value = '';
  option.text = 'Select object pronoun';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  array.forEach((pronoun) => {
    const option2 = document.createElement('option');
    option2.value = pronoun;
    option2.text = pronoun;
    newElementVal.appendChild(option2);
  });

  if (defaultValueData.default) {
    let intVal;
    defaultValueData.default.forEach((d) => {
      if (d.name === 'object_pronoun') {
        intVal = d.value;
      }
    });
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addPossessivePronounGoalFormElement() {
  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('possessive-pronoun-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Possessive pronoun ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'possessive_pronoun';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = ['her', 'his', 'their'];
  const option = document.createElement('option');
  option.value = '';
  option.text = 'Select possessive pronoun';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  array.forEach((pronoun) => {
    const option2 = document.createElement('option');
    option2.value = pronoun;
    option2.text = pronoun;
    newElementVal.appendChild(option2);
  });

  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'possessive_pronoun') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addPositionInWordGoalFormElement() {
  const position_in_words = JSON.parse(document.getElementById('position_in_word_data').dataset.source);

  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('position-in-word-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Position in word ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'position_in_word';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = position_in_words;
  var option = document.createElement('option');
  option.value = '';
  option.text = 'Select position in word';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  for (let i = 0; i < array.length; i += 1) {
    var option = document.createElement('option');
    option.value = array[i];
    option.text = array[i];
    newElementVal.appendChild(option);
  }

  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'position_in_word') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addTargetLevelGoalFormElement() {
  const target_levels = JSON.parse(document.getElementById('target_level_data').dataset.source);

  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('target-level-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Target level ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'target_level';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = target_levels;
  var option = document.createElement('option');
  option.value = '';
  option.text = 'Select target level';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  for (let i = 0; i < array.length; i += 1) {
    var option = document.createElement('option');
    option.value = array[i];
    option.text = array[i];
    newElementVal.appendChild(option);
  }

  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'target_level') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addBlendGoalFormElement() {
  const blends = JSON.parse(document.getElementById('blend_data').dataset.source);

  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('blend-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Blend ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'blend';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = blends;
  var option = document.createElement('option');
  option.value = '';
  option.text = 'Select blend';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  for (let i = 0; i < array.length; i += 1) {
    var option = document.createElement('option');
    option.value = array[i];
    option.text = array[i];
    newElementVal.appendChild(option);
  }

  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'blend') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addBlendGroupGoalFormElement() {
  const blendGroups = JSON.parse(document.getElementById('blend_group_data').dataset.source);

  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('blend-group-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Blend group ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'blend_group';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = blendGroups;
  var option = document.createElement('option');
  option.value = '';
  option.text = 'Select blend group';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  for (let i = 0; i < array.length; i += 1) {
    var option = document.createElement('option');
    option.value = array[i];
    option.text = array[i];
    newElementVal.appendChild(option);
  }
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'blend_group') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addPhonemeCategoryGoalFormElement() {
  const phonemeCategories = JSON.parse(document.getElementById('phoneme_category_data').dataset.source);

  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('phoneme-category-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Phoneme category ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'phoneme_category';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = phonemeCategories;
  var option = document.createElement('option');
  option.value = '';
  option.text = 'Select phoneme category';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  for (let i = 0; i < array.length; i += 1) {
    var option = document.createElement('option');
    option.value = array[i];
    option.text = array[i];
    newElementVal.appendChild(option);
  }
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'phoneme_category') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addPhonemeGroupGoalFormElement() {
  const phonemeGroups = JSON.parse(document.getElementById('phoneme_group_data').dataset.source);

  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('phoneme-group-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Phoneme group ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'phoneme_group';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = phonemeGroups;
  var option = document.createElement('option');
  option.value = '';
  option.text = 'Select phoneme group';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  for (let i = 0; i < array.length; i += 1) {
    var option = document.createElement('option');
    option.value = array[i];
    option.text = array[i];
    newElementVal.appendChild(option);
  }
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'phoneme_group') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addPhonemeGoalFormElement() {
  const phonemes = JSON.parse(document.getElementById('phoneme_data').dataset.source);
  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('phoneme-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Phoneme ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'phoneme';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('select');
  newElementVal.classList.add('form-control');
  newElementVal.required = true;
  newElementVal.name = 'goal[default_values][default][][value]';

  const array = phonemes;
  var option = document.createElement('option');
  option.value = '';
  option.text = 'Select phoneme';
  option.disabled = true;
  option.selected = true;
  newElementVal.appendChild(option);
  for (let i = 0; i < array.length; i += 1) {
    const optionGroup = document.createElement('optgroup');
    optionGroup.setAttribute('label', array[i][0]);
    const inner_array = array[i][1];
    for (let j = 0; j < inner_array.length; j++) {
      var option = document.createElement('option');
      option.value = inner_array[j][1];
      option.text = inner_array[j][0];
      optionGroup.appendChild(option);
    }
    newElementVal.appendChild(optionGroup);
  }
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'phoneme') {
        intVal = defaultValue.value;
      }
    }
    if (intVal !== undefined) {
      newElementVal.value = intVal;
    }
  }

  containerElementInner.appendChild(newElementVal);
}

function addStringGoalFormElement() {
  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('string-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'String ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'string') {
        intVal = defaultValue.value;
      }
    }
    if (intVal === undefined) {
      newElementName.value = 'string';
    } else {
      newElementVal.value = intVal;
    }
  } else {
    newElementName.value = 'string';
  }
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('input');
  newElementVal.setAttribute('type', 'text');
  newElementVal.setAttribute('placeholder', 'Add a default value');
  newElementVal.required = true;
  newElementVal.classList.add('form-control');
  newElementVal.name = 'goal[default_values][default][][value]';
  containerElementInner.appendChild(newElementVal);
}

function addIntegerGoalFormElement() {
  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('integer-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Integer ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'integer';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('input');
  newElementVal.setAttribute('type', 'number');
  newElementVal.required = true;
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'integer') {
        intVal = defaultValue.value;
      }
    }
    if (intVal === undefined) {
      newElementVal.value = 5;
    } else {
      newElementVal.value = intVal;
    }
  } else {
    newElementVal.value = 5;
  }
  newElementVal.classList.add('form-control');
  newElementVal.name = 'goal[default_values][default][][value]';
  containerElementInner.appendChild(newElementVal);
}

function addPercentageGoalFormElement() {
  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('percentage-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  const containerElementInputGroup = document.createElement('div');
  containerElementInputGroup.classList.add('input-group');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Percentage ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  containerElementInner.appendChild(containerElementInputGroup);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInputGroup.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'percentage';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInputGroup.appendChild(newElementName);

  const newElementVal = document.createElement('input');
  newElementVal.setAttribute('type', 'number');
  newElementVal.required = true;
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'percentage') {
        intVal = defaultValue.value;
      }
    }
    if (intVal === undefined) {
      newElementVal.value = 80;
    } else {
      newElementVal.value = intVal;
    }
  } else {
    newElementVal.value = 80;
  }
  newElementVal.classList.add('form-control');
  newElementVal.name = 'goal[default_values][default][][value]';
  containerElementInputGroup.appendChild(newElementVal);

  const newElementGroup = document.createElement('div');
  newElementGroup.classList.add('input-group-append');

  const newElementSpan = document.createElement('span');
  newElementSpan.classList.add('input-group-text');
  newElementSpan.innerHTML = '%';

  newElementGroup.appendChild(newElementSpan);
  containerElementInputGroup.appendChild(newElementGroup);
}

function addPatientNameGoalFormElement() {
  const dfElement = document.getElementById('default_fields_container');
  dfElement.classList.remove('d-none');

  const containerElement = document.createElement('div');
  containerElement.classList.add('form-row');
  containerElement.classList.add('flex-row');
  containerElement.classList.add('mb-2');
  containerElement.classList.add('patient-name-container');

  const containerElementInner = document.createElement('div');
  containerElementInner.classList.add('col-6');

  dfElement.appendChild(containerElement);
  containerElement.appendChild(containerElementInner);

  const newElementLabel = document.createElement('label');
  newElementLabel.innerHTML = 'Patient name ';
  const containerElementSpan = document.createElement('span');
  containerElementSpan.classList.add('required-field');
  containerElementSpan.innerHTML = '*';
  newElementLabel.appendChild(containerElementSpan);
  containerElementInner.appendChild(newElementLabel);

  const newElementPosition = document.createElement('input');
  newElementPosition.setAttribute('type', 'hidden');
  newElementPosition.value = goalPositionTracker;
  goalPositionTracker += 1;
  newElementPosition.name = 'goal[default_values][default][][position]';
  containerElementInner.appendChild(newElementPosition);

  const newElementName = document.createElement('input');
  newElementName.setAttribute('type', 'hidden');
  newElementName.value = 'patient_name';
  newElementName.name = 'goal[default_values][default][][name]';
  containerElementInner.appendChild(newElementName);

  const newElementVal = document.createElement('input');
  newElementVal.setAttribute('type', 'text');
  newElementVal.required = true;
  if (defaultValueData.default) {
    let intVal;
    for (let i = 0; i < defaultValueData.default.length; i += 1) {
      const defaultValue = defaultValueData.default[i];
      if (defaultValue.name === 'patient_name') {
        intVal = defaultValue.value;
      }
    }
    if (intVal === undefined) {
      newElementVal.value = 'Jane';
    } else {
      newElementVal.value = intVal;
    }
  } else {
    newElementVal.value = 'Jane';
  }
  newElementVal.classList.add('form-control');
  newElementVal.name = 'goal[default_values][default][][value]';
  containerElementInner.appendChild(newElementVal);
}

function selectAll(e) {
  const range = document.createRange();
  range.selectNode(e.target);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
}

function setUpCustomTagListeners() {
  const customTags = document.querySelectorAll('gc-who, gc-skill ,gc-context, gc-measurable, gc-reason, gc-timeframe');
  for (let i = 0; i < customTags.length; i += 1) {
    const tag = customTags[i];
    tag.removeEventListener('click', selectAll);
    tag.addEventListener('click', selectAll);
  }
}

export function rebuildDynamicElements() {
  if (!defaultValueData) setDefaultDataValue();
  const dfElement = document.getElementById('default_fields_container');
  while (dfElement.firstChild) {
    dfElement.removeChild(dfElement.firstChild);
  }
  const containerElement = document.createElement('h4');
  containerElement.textContent = 'Default values';
  dfElement.appendChild(containerElement);

  const element = document.querySelector('trix-editor');
  const trixDocument = element.editor.getDocument();
  const trixString = trixDocument.toString();
  const matches = trixString.match(/{{[^{]*}}/g);

  if (matches !== null) {
    for (let i = 0; i < matches.length; i += 1) {
      if (matches[i] === '{{patient_name}}') {
        addPatientNameGoalFormElement();
      } else if (matches[i] === '{{percentage}}') {
        addPercentageGoalFormElement();
      } else if (matches[i] === '{{integer}}') {
        addIntegerGoalFormElement();
      } else if (matches[i] === '{{string}}') {
        addStringGoalFormElement();
      } else if (matches[i] === '{{phoneme}}') {
        addPhonemeGoalFormElement();
      } else if (matches[i] === '{{phoneme_category}}') {
        addPhonemeCategoryGoalFormElement();
      } else if (matches[i] === '{{phoneme_group}}') {
        addPhonemeGroupGoalFormElement();
      } else if (matches[i] === '{{blend}}') {
        addBlendGoalFormElement();
      } else if (matches[i] === '{{blend_group}}') {
        addBlendGroupGoalFormElement();
      } else if (matches[i] === '{{target_level}}') {
        addTargetLevelGoalFormElement();
      } else if (matches[i] === '{{position_in_word}}') {
        addPositionInWordGoalFormElement();
      } else if (matches[i] === '{{possessive_pronoun}}') {
        addPossessivePronounGoalFormElement();
      } else if (matches[i] === '{{object_pronoun}}') {
        addObjectPronounGoalFormElement();
      }
    }
  }
  resetGoalFormPositions();
}

export function goalsTrixInitialize(event) {
  if (!defaultValueData) setDefaultDataValue();
  const editor = event.target;
  const toolbar = editor.toolbarElement;
  const ttools = toolbar.querySelector('.trix-button-group--text-tools');
  const btools = toolbar.querySelector('.trix-button-group--block-tools');
  const ftools = toolbar.querySelector('.trix-button-group--file-tools');

  const goals = `
      <span class="trix-button-group trix-button-group--goal-tools" data-trix-button-group="goal-tools">
        <button type="button" class="trix-button" data-trix-attribute="g-patient" data-trix-action="x-patient" title="Add patient name" tabindex="-1">Patient name</button>
        <button type="button" class="trix-button" data-trix-attribute="g-percentage" data-trix-action="x-percentage" title="Add number blank (percentage)" tabindex="-1">Percentage</button>
        <button type="button" class="trix-button" data-trix-attribute="g-integer" data-trix-action="x-integer" title="Add number blank (integer)" tabindex="-1">Integer</button>
        <button type="button" class="trix-button" data-trix-attribute="g-string" data-trix-action="x-string" title="Add text blank" tabindex="-1">String</button>
        <button type="button" class="trix-button" data-trix-attribute="g-possessive-pronoun" data-trix-action="x-possessive-pronoun" title="Add possessive pronoun (e.g. her, his, their)" tabindex="-1">Possessive pronoun</button>
        <button type="button" class="trix-button" data-trix-attribute="g-object-pronoun" data-trix-action="x-object-pronoun" title="Add object pronoun (e.g. she, he, they)" tabindex="-1">Object pronoun</button>
      </span>
    `;

  const speechGoals = `
      <span class="trix-button-group trix-button-group--speech-goal-tools" data-trix-button-group="speech-goal-tools">
        <button type="button" class="trix-button" data-trix-attribute="g-phoneme" data-trix-action="x-phoneme" title="Add phoneme" tabindex="-1">Phoneme</button>
        <button type="button" class="trix-button" data-trix-attribute="g-phoneme-category" data-trix-action="x-phoneme-category" title="Add phoneme category" tabindex="-1">Phoneme category</button>
        <button type="button" class="trix-button" data-trix-attribute="g-phoneme-group" data-trix-action="x-phoneme-group" title="Add phoneme group" tabindex="-1">Phoneme group</button>
        <button type="button" class="trix-button" data-trix-attribute="g-blend" data-trix-action="x-blend" title="Add blend" tabindex="-1">Blend</button>
        <button type="button" class="trix-button" data-trix-attribute="g-blend-group" data-trix-action="x-blend-group" title="Add blend group" tabindex="-1">Blend group</button>
        <button type="button" class="trix-button" data-trix-attribute="g-target-level" data-trix-action="x-target-level" title="Add target level" tabindex="-1">Target level</button>
        <button type="button" class="trix-button" data-trix-attribute="g-position-in-word" data-trix-action="x-position-in-word" title="Add position in word of phoneme/blend" tabindex="-1">Position in word</button>
      </span>
    `;

  const components = `
      <span class="trix-button-group trix-button-group--component-tools" data-trix-button-group="component-tools">
        <button type="button" class="trix-button" data-trix-attribute="gc-who" data-trix-action="x-gcwho" title="Goal component - Who" tabindex="-1">Who</button>
        <button type="button" class="trix-button" data-trix-attribute="gc-skill" data-trix-action="x-gcimpairment" title="Goal component - Skill / impairment" tabindex="-1">Skill</button>
        <button type="button" class="trix-button" data-trix-attribute="gc-context" data-trix-action="x-gccontext" title="Goal component - Context" tabindex="-1">Context</button>
        <button type="button" class="trix-button" data-trix-attribute="gc-measurable" data-trix-action="x-gcmeasurable" title="Goal component - Measurable" tabindex="-1">Measurable</button>
        <button type="button" class="trix-button" data-trix-attribute="gc-reason" data-trix-action="x-gcreason" title="Goal component - Reason" tabindex="-1">Reason</button>
        <button type="button" class="trix-button" data-trix-attribute="gc-timeframe" data-trix-action="x-gctimeframe" title="Goal component - Time frame" tabindex="-1">Time frame</button>
      </span>
    `;

  ttools.insertAdjacentHTML('beforebegin', goals);
  ttools.insertAdjacentHTML('beforebegin', speechGoals);
  ttools.insertAdjacentHTML('beforebegin', components);
  ttools.remove();
  btools.remove();
  ftools.remove();

  const element = document.querySelector('trix-editor');
  const trixDocument = element.editor.getDocument();
  const trixString = trixDocument.toString();
  const matches = trixString.match(/{{[^{]*}}/g);

  if (matches !== null) {
    for (let i = 0; i < matches.length; i += 1) {
      if (matches[i] === '{{patient_name}}') {
        addPatientNameGoalFormElement();
      } else if (matches[i] === '{{percentage}}') {
        addPercentageGoalFormElement();
      } else if (matches[i] === '{{integer}}') {
        addIntegerGoalFormElement();
      } else if (matches[i] === '{{string}}') {
        addStringGoalFormElement();
      } else if (matches[i] === '{{phoneme}}') {
        addPhonemeGoalFormElement();
      } else if (matches[i] === '{{phoneme_category}}') {
        addPhonemeCategoryGoalFormElement();
      } else if (matches[i] === '{{phoneme_group}}') {
        addPhonemeGroupGoalFormElement();
      } else if (matches[i] === '{{blend}}') {
        addBlendGoalFormElement();
      } else if (matches[i] === '{{blend_group}}') {
        addBlendGroupGoalFormElement();
      } else if (matches[i] === '{{target_level}}') {
        addTargetLevelGoalFormElement();
      } else if (matches[i] === '{{position_in_word}}') {
        addPositionInWordGoalFormElement();
      } else if (matches[i] === '{{possessive_pronoun}}') {
        addPossessivePronounGoalFormElement();
      } else if (matches[i] === '{{object_pronoun}}') {
        addObjectPronounGoalFormElement();
      }
    }
  }
  resetGoalFormPositions();
  setUpCustomTagListeners();

  if (document.getElementsByTagName('gc-who').length > 0) {
    const tfElement = document.getElementById('goal-who');
    while (tfElement.firstChild) {
      tfElement.removeChild(tfElement.firstChild);
    }
    const newElement = document.createElement('s');
    const newContent = document.createTextNode('Who');
    newElement.classList.add('text-muted');
    newElement.appendChild(newContent);
    tfElement.appendChild(newElement);
  }

  if (document.getElementsByTagName('gc-skill').length > 0) {
    const tfElement = document.getElementById('goal-skill');
    while (tfElement.firstChild) {
      tfElement.removeChild(tfElement.firstChild);
    }
    const newElement = document.createElement('s');
    const newContent = document.createTextNode('Skill');
    newElement.classList.add('text-muted');
    newElement.appendChild(newContent);
    tfElement.appendChild(newElement);
  }

  if (document.getElementsByTagName('gc-context').length > 0) {
    const tfElement = document.getElementById('goal-context');
    while (tfElement.firstChild) {
      tfElement.removeChild(tfElement.firstChild);
    }
    const newElement = document.createElement('s');
    const newContent = document.createTextNode('Context');
    newElement.classList.add('text-muted');
    newElement.appendChild(newContent);
    tfElement.appendChild(newElement);
  }

  if (document.getElementsByTagName('gc-measurable').length > 0) {
    const tfElement = document.getElementById('goal-measurable');
    while (tfElement.firstChild) {
      tfElement.removeChild(tfElement.firstChild);
    }
    const newElement = document.createElement('s');
    const newContent = document.createTextNode('Measurable');
    newElement.classList.add('text-muted');
    newElement.appendChild(newContent);
    tfElement.appendChild(newElement);
  }

  if (document.getElementsByTagName('gc-reason').length > 0) {
    const tfElement = document.getElementById('goal-function');
    while (tfElement.firstChild) {
      tfElement.removeChild(tfElement.firstChild);
    }
    const newElement = document.createElement('s');
    const newContent = document.createTextNode('Reason');
    newElement.classList.add('text-muted');
    newElement.appendChild(newContent);
    tfElement.appendChild(newElement);
  }

  if (document.getElementsByTagName('gc-timeframe').length > 0) {
    const tfElement = document.getElementById('goal-timeframe');
    while (tfElement.firstChild) {
      tfElement.removeChild(tfElement.firstChild);
    }
    const newElement = document.createElement('s');
    const newContent = document.createTextNode('Time Frame');
    newElement.classList.add('text-muted');
    newElement.appendChild(newContent);
    tfElement.appendChild(newElement);
  }

  document.addEventListener('trix-action-invoke', (e) => {
    setUpCustomTagListeners();
    if (e.actionName === 'x-gcwho') {
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const tfCount = document.getElementsByTagName('gc-who').length;
        if (tfCount > 0) {
          const tfElement = document.getElementById('goal-who');
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          const newElement = document.createElement('s');
          const newContent = document.createTextNode('Who');
          newElement.classList.add('text-muted');
          newElement.appendChild(newContent);
          tfElement.appendChild(newElement);
        } else {
          const tfElement = document.getElementById('goal-who');
          const tfText = tfElement.textContent;
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          tfElement.textContent = 'Who';
        }
      }, 1);
    }

    if (e.actionName === 'x-gcimpairment') {
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const tfCount = document.getElementsByTagName('gc-skill').length;
        if (tfCount > 0) {
          const tfElement = document.getElementById('goal-skill');
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          const newElement = document.createElement('s');
          const newContent = document.createTextNode('Skill');
          newElement.classList.add('text-muted');
          newElement.appendChild(newContent);
          tfElement.appendChild(newElement);
        } else {
          const tfElement = document.getElementById('goal-skill');
          const tfText = tfElement.textContent;
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          tfElement.textContent = 'Skill';
        }
      }, 1);
    }

    if (e.actionName === 'x-gccontext') {
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const tfCount = document.getElementsByTagName('gc-context').length;
        if (tfCount > 0) {
          const tfElement = document.getElementById('goal-context');
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          const newElement = document.createElement('s');
          const newContent = document.createTextNode('Context');
          newElement.classList.add('text-muted');
          newElement.appendChild(newContent);
          tfElement.appendChild(newElement);
        } else {
          const tfElement = document.getElementById('goal-context');
          const tfText = tfElement.textContent;
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          tfElement.textContent = 'Context';
        }
      }, 1);
    }

    if (e.actionName === 'x-gcmeasurable') {
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const tfCount = document.getElementsByTagName('gc-measurable').length;
        if (tfCount > 0) {
          const tfElement = document.getElementById('goal-measurable');
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          const newElement = document.createElement('s');
          const newContent = document.createTextNode('Measurable');
          newElement.classList.add('text-muted');
          newElement.appendChild(newContent);
          tfElement.appendChild(newElement);
        } else {
          const tfElement = document.getElementById('goal-measurable');
          const tfText = tfElement.textContent;
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          tfElement.textContent = 'Measurable';
        }
      }, 1);
    }

    if (e.actionName === 'x-gcreason') {
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const tfCount = document.getElementsByTagName('gc-reason').length;
        if (tfCount > 0) {
          const tfElement = document.getElementById('goal-function');
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          const newElement = document.createElement('s');
          const newContent = document.createTextNode('Reason');
          newElement.classList.add('text-muted');
          newElement.appendChild(newContent);
          tfElement.appendChild(newElement);
        } else {
          const tfElement = document.getElementById('goal-function');
          const tfText = tfElement.textContent;
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          tfElement.textContent = 'Reason';
        }
      }, 1);
    }

    if (e.actionName === 'x-gctimeframe') {
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const tfCount = document.getElementsByTagName('gc-timeframe').length;
        if (tfCount > 0) {
          const tfElement = document.getElementById('goal-timeframe');
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          const newElement = document.createElement('s');
          const newContent = document.createTextNode('Time Frame');
          newElement.classList.add('text-muted');
          newElement.appendChild(newContent);
          tfElement.appendChild(newElement);
        } else {
          const tfElement = document.getElementById('goal-timeframe');
          const tfText = tfElement.textContent;
          while (tfElement.firstChild) {
            tfElement.removeChild(tfElement.firstChild);
          }
          tfElement.textContent = 'Time Frame';
        }
      }, 1);
    }

    if (e.actionName === 'x-patient') {
      element.editor.insertString('{{patient_name}} ');

      addPatientNameGoalFormElement();
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-percentage') {
      element.editor.insertString('{{percentage}}% ');
      addPercentageGoalFormElement();
      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-integer') {
      element.editor.insertString('{{integer}} ');
      addIntegerGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-string') {
      element.editor.insertString('{{string}} ');
      addStringGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-phoneme') {
      element.editor.insertString('/{{phoneme}}/ ');
      addPhonemeGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-phoneme-category') {
      element.editor.insertString('{{phoneme_category}} ');
      addPhonemeCategoryGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-phoneme-group') {
      element.editor.insertString('{{phoneme_group}} ');
      addPhonemeGroupGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-blend') {
      element.editor.insertString('{{blend}} ');
      addBlendGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-blend-group') {
      element.editor.insertString('{{blend_group}} ');
      addBlendGroupGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-target-level') {
      element.editor.insertString('{{target_level}} ');
      addTargetLevelGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-position-in-word') {
      element.editor.insertString('{{position_in_word}} ');
      addPositionInWordGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-possessive-pronoun') {
      element.editor.insertString('{{possessive_pronoun}} ');
      addPossessivePronounGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }

    if (e.actionName === 'x-object-pronoun') {
      element.editor.insertString('{{object_pronoun}} ');
      addObjectPronounGoalFormElement();

      // I can't find an event that fires after trix-active is added, so we'll have
      // to settle for a hacky setTimeout for now.
      setTimeout(() => {
        const els = document.getElementsByClassName('trix-active');
        for (let i = 0; i < els.length; i += 1) {
          els[i].classList.remove('trix-active');
        }
      }, 1);
    }
  });

  document.addEventListener('trix-change', () => {
    setUpCustomTagListeners();

    const trixDocument = element.editor.getDocument();
    const trixString = trixDocument.toString();
    const patientNameCount = (trixString.match(/{{patient_name}}/g) || []).length;
    const percentageCount = (trixString.match(/{{percentage}}/g) || []).length;
    const integerCount = (trixString.match(/{{integer}}/g) || []).length;
    const stringCount = (trixString.match(/{{string}}/g) || []).length;
    const possessivePronounCount = (trixString.match(/{{possessive_pronoun}}/g) || []).length;
    const objectPronounCount = (trixString.match(/{{object_pronoun}}/g) || []).length;
    const phonemeCategoryCount = (trixString.match(/{{phoneme_category}}/g) || []).length;
    const phonemeGroupCount = (trixString.match(/{{phoneme_group}}/g) || []).length;
    const blendCount = (trixString.match(/{{blend}}/g) || []).length;
    const blendGroupCount = (trixString.match(/{{blend_group}}/g) || []).length;
    const phonemeCount = (trixString.match(/{{phoneme}}/g) || []).length;
    const targetLevelCount = (trixString.match(/{{target_level}}/g) || []).length;
    const positionInWordCount = (trixString.match(/{{position_in_word}}/g) || []).length;

    const array1 = previousString.match(/{{[^{]*}}/g);
    let patientNameIndices1;
    let percentageIndices1;
    let integerIndices1;
    let stringIndices1;
    let possessivePronounIndices1;
    let objectPronounIndices1;
    let phonemeCategoryIndices1;
    let phonemeGroupIndices1;
    let blendIndices1;
    let blendGroupIndices1;
    let phonemeIndices1;
    let targetLevelIndices1;
    let positionInWordIndices1;
    if (array1) {
      patientNameIndices1 = getAllIndexes(array1, '{{patient_name}}');
      percentageIndices1 = getAllIndexes(array1, '{{percentage}}');
      integerIndices1 = getAllIndexes(array1, '{{integer}}');
      stringIndices1 = getAllIndexes(array1, '{{string}}');
      possessivePronounIndices1 = getAllIndexes(array1, '{{possessive_pronoun}}');
      objectPronounIndices1 = getAllIndexes(array1, '{{object_pronoun}}');
      phonemeCategoryIndices1 = getAllIndexes(array1, '{{phoneme_category}}');
      phonemeGroupIndices1 = getAllIndexes(array1, '{{phoneme_group}}');
      blendIndices1 = getAllIndexes(array1, '{{blend}}');
      blendGroupIndices1 = getAllIndexes(array1, '{{blend_group}}');
      phonemeIndices1 = getAllIndexes(array1, '{{phoneme}}');
      targetLevelIndices1 = getAllIndexes(array1, '{{target_level}}');
      positionInWordIndices1 = getAllIndexes(array1, '{{position_in_word}}');
    }

    const array2 = trixString.match(/{{[^{]*}}/g);
    let patientNameIndices2;
    let percentageIndices2;
    let integerIndices2;
    let stringIndices2;
    let possessivePronounIndices2;
    let objectPronounIndices2;
    let phonemeCategoryIndices2;
    let phonemeGroupIndices2;
    let blendIndices2;
    let blendGroupIndices2;
    let phonemeIndices2;
    let targetLevelIndices2;
    let positionInWordIndices2;
    if (array2) {
      patientNameIndices2 = getAllIndexes(array2, '{{patient_name}}');
      percentageIndices2 = getAllIndexes(array2, '{{percentage}}');
      integerIndices2 = getAllIndexes(array2, '{{integer}}');
      stringIndices2 = getAllIndexes(array2, '{{string}}');
      possessivePronounIndices2 = getAllIndexes(array2, '{{possessive_pronoun}}');
      objectPronounIndices2 = getAllIndexes(array2, '{{object_pronoun}}');
      phonemeCategoryIndices2 = getAllIndexes(array2, '{{phoneme_category}}');
      phonemeGroupIndices2 = getAllIndexes(array2, '{{phoneme_group}}');
      blendIndices2 = getAllIndexes(array2, '{{blend}}');
      blendGroupIndices2 = getAllIndexes(array2, '{{blend_group}}');
      phonemeIndices2 = getAllIndexes(array2, '{{phoneme}}');
      targetLevelIndices2 = getAllIndexes(array2, '{{target_level}}');
      positionInWordIndices2 = getAllIndexes(array2, '{{position_in_word}}');
    }

    const elements = document.getElementsByName('goal[default_values][default][][name]');
    let patientNameCounter = 0;
    let percentageCounter = 0;
    let integerCounter = 0;
    let stringCounter = 0;
    let possessivePronounCounter = 0;
    let objectPronounCounter = 0;
    let phonemeCategoryCounter = 0;
    let phonemeGroupCounter = 0;
    let blendCounter = 0;
    let blendGroupCounter = 0;
    let phonemeCounter = 0;
    let targetLevelCounter = 0;
    let positionInWordCounter = 0;
    setTimeout(() => {
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].value === 'patient_name') {
          patientNameCounter += 1;
        }
        if (elements[i].value === 'percentage') {
          percentageCounter += 1;
        }
        if (elements[i].value === 'integer') {
          integerCounter += 1;
        }
        if (elements[i].value === 'string') {
          stringCounter += 1;
        }
        if (elements[i].value === 'possessive_pronoun') {
          possessivePronounCounter += 1;
        }
        if (elements[i].value === 'object_pronoun') {
          objectPronounCounter += 1;
        }
        if (elements[i].value === 'phoneme_category') {
          phonemeCategoryCounter += 1;
        }
        if (elements[i].value === 'phoneme_group') {
          phonemeGroupCounter += 1;
        }
        if (elements[i].value === 'blend') {
          blendCounter += 1;
        }
        if (elements[i].value === 'blend_group') {
          blendGroupCounter += 1;
        }
        if (elements[i].value === 'phoneme') {
          phonemeCounter += 1;
        }
        if (elements[i].value === 'target_level') {
          targetLevelCounter += 1;
        }
        if (elements[i].value === 'position_in_word') {
          positionInWordCounter += 1;
        }
      }
      if (
        patientNameCounter > patientNameCount ||
        percentageCounter > percentageCount ||
        integerCounter > integerCount ||
        stringCounter > stringCount ||
        possessivePronounCounter > possessivePronounCount ||
        objectPronounCounter > objectPronounCount ||
        phonemeCategoryCounter > phonemeCategoryCount ||
        phonemeGroupCounter > phonemeGroupCount ||
        blendCounter > blendCount ||
        blendGroupCounter > blendGroupCount ||
        phonemeCounter > phonemeCount ||
        targetLevelCounter > targetLevelCount ||
        positionInWordCounter > positionInWordCount
      ) {
        rebuildDynamicElements();
      }
      resetGoalFormPositions();
    }, 1);

    previousString = trixString;
  });
}
