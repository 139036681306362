function defineCustomElement(module, identifier) {
  const definition = module.default;
  const { name } = definition;
  if (typeof definition !== 'function') return;

  if (!window.customElements.get(identifier)) {
    window[name] = definition;
    window.customElements.define(identifier, definition);
  }
}

function identifierForContextKey(key) {
  const logicalName = (key.match(/^(?:\.\/)?(.+)(?:[-]element\..+?)$/) || [])[1];
  if (logicalName) {
    const array = logicalName.split('/');
    return array[array.length - 1];
  }

  return false;
}

export default function registerCustomElement(r) {
  r.keys().forEach((key) => {
    const identifier = identifierForContextKey(key);
    if (identifier) {
      defineCustomElement(r(key), identifier);
    }
  });
}
