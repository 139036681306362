// This class creates a general action cable channel
// for teletherapy participants
import VisualScheduleChannel from '../../channels/visual_schedule_channel';

export default class VisualScheduleChannelCreator {
  constructor(options = {}) {
    this.store = options.store;
    this.sessionId = options.sessionId;
  }

  createChannel = async () => {
    const dataChannel = new VisualScheduleChannel({
      store: this.store,
      sessionId: this.sessionId,
    });
    await dataChannel.init().then(() => {
      this.store.commit('storeVisualScheduleChannel', dataChannel.channel);
    });
  }
}
