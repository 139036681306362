import consumer from './consumer';
import RoomVisitorChannel from './room_visitor_channel';
import log from '../lib/logger';

export default class RoomChannel {
  constructor(options) {
    this.store = options.store; // Vuex store
    this.roomId = options.roomId;
    this.viewerId = options.viewerId || this.store.state.userId;
    this.channel = null;
    this.isResolved = null;
  }

  init = () =>
    new Promise((resolve) => {
      this.isResolved = resolve;
      this.createSubscription();
    });

  createSubscription = () => {
    const { roomId, viewerId, store, isResolved } = this;

    this.channel = consumer.subscriptions.create(
      {
        channel: 'RoomChannel',
        id: roomId,
        user_id: viewerId,
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
          isResolved();
          log.info('Room Channel connected');
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          log.info('Room Channel disconnected');
        },

        received: async (data) => {
          log.debug('Room Channel data received', data);
          if (data.type === 'new_visitor') {
            if (!data.visitor.id) return;
            if (data.visitor.id === store.state.visitor.id) return;
            log.info('Room visitor arrived');
            log.debug('Arrived visitor', data.visitor);
            store.dispatch('handleRoomVisitorArrival', { visitor: data.visitor, sendReply: true });
          } else if (data.type === 'help_requested') {
            log.info('Help requested');
            log.debug('Visitor requesting help', data.visitorId);
            store.commit('handleVisitorRequestingHelp', { visitorId: data.visitorId, requestedHelp: true });
          } else if (data.type === 'session_id') {
            if (store.state.visitor.eventId === data.eventId) {
              store.commit('setActiveSessionId', data.sessionId);
              // If the participant(patient/observer) joins earlier than the therapist, their `roomsSessionId` is null.
              // So when the therapist opens the session, we set their respective `roomsSessionId`.
              store.commit('updateVisitor', { roomsSessionId: data.sessionId });
            }
          } else if (data.visitor_left !== undefined) {
            log.info('Room visitor left');
            log.debug('Leaving visitor data', data.visitor_left);
            store.dispatch('handleRoomVisitorDeparture', data.visitor_left);
          } else if (data.type === 'visitor_left') {
            log.info('Room visitor left');
            log.debug('Leaving visitor data', data.visitor.id);
            store.dispatch('handleRoomVisitorDeparture', data.visitor.id);
          } else if (data.type === 'new_visitor_channel') {
            // Do nothing if it's already subscribed
            if (store.state.roomVisitorChannels[data.sentBy]) return;
            // If the room owner, need to connect to the RoomVisitorChannel
            // of the visitor sending the request
            if (store.state.visitor.id === store.state.ownerId) {
              const channel = new RoomVisitorChannel({ roomId: this.roomId, line: data.line, store });
              await channel.init();
              this.store.commit('storeVisitorChannel', {
                id: data.sentBy,
                line: channel.line,
                channel: channel.channel,
              });
            }
          } else if (data.type === 'reload_window') {
            if (store.state.visitor.id === data.id) {
              window.location.reload();
            }
          } else if (data.type === 'show_notification_modal') {
            if (store.state.visitor.id === data.id) {
              store.commit('setNotificationModalMsg', data.message);
            }
          } else if (data.type === 'welcome_reply' && data.visitor.id !== store.state.visitor.id) {
            store.dispatch('handleRoomVisitorArrival', { visitor: data.visitor, sendReply: false });
            if (store.state.ownerId === store.state.visitor.id) {
              store.commit('storeActiveVisitorAudioMessage', { visitorId: data.sentBy, base64Audio: data.audio });
              store.commit('storeActiveVisitorName', { visitorId: data.sentBy, enteredName: data.name });
            }
          } else if (data.type === 'end_session' && data.id !== store.state.visitor.id) {
            store.commit('layout/setCallRatingVisibility', true);
          }
        },
      }
    );
  };
}
