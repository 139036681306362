import axios from 'axios';
import debounce from 'lodash.debounce';
import { brandedId } from '../../helpers/random-id';

export default class CommandPaletteGroupElement extends HTMLElement {
  connectedCallback() {
    if (!this.hasAttribute('data-group-id')) {
      this.setAttribute('data-group-id', brandedId());
    }

    // Set `role` and label with the existing group title
    this.setAttribute('role', 'listbox');
    const title = this.querySelector('[data-group-title]');
    if (title) {
      const titleId = brandedId();
      title.id = titleId;
      this.setAttribute('aria-labelledby', titleId);
    }

    this.onChange = this.onChange.bind(this);
    this.fetchResults = debounce(this.fetchResults.bind(this), 300);

    this.addEventListener('command-palette:change', this.onChange);
  }

  disconnectedCallback() {
    this.removeEventListener('command-palette:change', this.onChange);
  }

  onChange(event) {
    const { query, scopeId } = event.detail;

    if (scopeId) {
      if (!isGroupActive(this, scopeId)) return;
      // if there is a src get remote items
      // else filter within the scope here
      if (this.src) {
        this.initializeFetch(this.src, query);
      } else {
        this.groupItems(scopeId).forEach(filterItems(query, { matching: 'data-search-term', scopeId }));
      }
    } else if (!this.src) {
      // filter all except the src
      this.items.forEach(filterItems(query, { matching: 'data-search-term' }));
    }
  }

  initializeFetch(src, query) {
    this.startLoading();
    this.fetchResults(src, query);
  }

  async fetchResults(src, query) {
    try {
      const { data } = await axios.get(src, {
        headers: { accept: 'text/fragment+html' },
        params: { q: query },
      });

      // Since this function is debounced, we cannot be sure that we'll always get fresh scoped data from the
      // arguments, hence we need to make a fresh search of the attributes;
      if ((!query && !this.palette.hasAttribute('data-active-group')) || !isGroupActive(this, this.activeGroup?.id)) {
        this.hidden = true;
        return;
      }

      this.innerHTML = data;
      this.hidden = !data; // Hide group if data is empty
    } catch (error) {
      this.innerHTML = renderErrorTemplate();
    } finally {
      this.stopLoading();
    }
  }

  startLoading() {
    this.palette?.dispatchEvent(
      new CustomEvent('command-palette-group:loadstart', { detail: { relatedTarget: this } })
    );
  }

  stopLoading() {
    this.palette?.dispatchEvent(new CustomEvent('command-palette-group:loadend', { detail: { relatedTarget: this } }));
  }

  groupItems(groupId) {
    if (!groupId || !isGroupActive(this, groupId)) return [];

    return this.items;
  }

  get src() {
    return this.getAttribute('src');
  }

  get items() {
    return Array.from(this.querySelectorAll('command-palette-item'));
  }

  get palette() {
    return this.closest('command-palette');
  }

  get activeGroup() {
    return JSON.parse(this.palette.getAttribute('data-active-group'));
  }
}

function filterItems(query, { matching, scopeId = null }) {
  return (target) => {
    const group = target.closest('command-palette-group');
    if (!(group instanceof HTMLElement)) return;

    if (query) {
      const value = target.getAttribute(matching) || target.textContent;
      const match = value?.toLowerCase().includes(query.toLowerCase());
      target.hidden = !match;

      const allItems = Array.from(group.querySelectorAll('command-palette-item'));
      group.hidden = allItems.every((item) => item.hidden);
    } else {
      target.hidden = false;
      if (scopeId) {
        group.hidden = !isGroupActive(group, scopeId); // Hide all except the active group
      } else {
        group.hidden = !group.hasAttribute('data-cp-homepage'); // Hide all except the homepage
      }
    }
  };
}

function isGroupActive(group, id) {
  return group.getAttribute('data-group-id') === id;
}

function renderErrorTemplate() {
  return `
    <div class="card text-center border-0 shadow-none mx-auto w-75">
      <div class="card-body">
        <h3 class="card-title text-danger">An error occurred.</h3>
        <p class="card-text text-danger">We can't process your data right now. Please try again later.</p>
      </div>
    </div>
  `;
}
