import { observe } from 'helpers/dom-helper';

/**
 * Initializes the bootstrap tooltip component when element is connected to the DOM and disposes it when element is
 * removed from the DOM.
 */
observe('[data-toggle="tooltip"]', {
  connected(element) {
    // Added boundary window to prevent flickering when a tooltip
    // is within a responsive table (Kevin 10/2022)
    // https://stackoverflow.com/a/62076857
    if ($.isFunction($('[data-toggle="tooltip"]').tooltip)) {
      $(element).tooltip({ boundary: 'window' });
    }
  },
  disconnected(element) {
    if ($.isFunction($('[data-toggle="tooltip"]').tooltip)) {
      $(element).tooltip('dispose');
    }
  },
});
