import ApplicationController from './application_controller';

/**
 *
 * This controls the side-navigation components active index and hides/displays the relevant items
 * to the right of the navigation container.
 *
 * @controller side-nav
 * @example please look at the site_map.html.erb and the relevant controller.
 *
 */
export default class extends ApplicationController {
  static targets = ['controlled', 'uncontrolled'];
  static values = { index: Number, active: Boolean, hasRunFirst: Boolean };

  initialize() {
    this.showCurrentSlide();
    this.hasRunFirst = true;
  }

  /**
   *
   * Updates the active index.
   * @function changeActiveIndex
   * @param {*} event
   *
   */
  changeActiveIndex(event) {
    const element = event.target;
    this.indexValue = element.dataset.uncontrolledIndex;
    this.showCurrentSlide();
  }

  /**
   *
   * Loops through the related subItems of the side navigation items
   * updates relevant classes and hides/shows the relevant items
   * based on the active index.
   * @function showCurrentSlide
   *
   */
  showCurrentSlide() {
    this.uncontrolledTargets.forEach((element, index) => {
      if (this.indexValue < -1) {
        this.indexValue = 0;
      }
      if (index === 0) element.classList.add('top-item');

      if (element.dataset.uncontrolledActive !== undefined && !this.hasRunFirst) {
        if (element.dataset.uncontrolledActive === 'true') {
          this.indexValue = element.dataset.uncontrolledIndex;
          element.classList.add('active-btn-site-map');
        } else {
          element.classList.remove('active-btn-site-map');
        }
      } else if (index === this.indexValue) {
        element.classList.add('active-btn-site-map');
      } else {
        element.classList.remove('active-btn-site-map');
      }
    });

    this.controlledTargets.forEach((element, index) => {
      if (this.indexValue < -1) {
        this.indexValue = 0;
      }
      element.hidden = index !== this.indexValue;
    });
  }
}
