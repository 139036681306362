import consumer from './consumer';
import { EventBus } from '../rooms/event-bus';
import log from '../lib/logger';

export default class ReactionsChannel {
  constructor(options = {}) {
    this.roomsSessionId = options.roomsSessionId;
    this.line = options.line; // Pass in object with participant IDs
    this.store = options.store; // Vuex store
    this.channel = null;
    this.isResolved = null;
  }

  init = () =>
    new Promise((resolve) => {
      this.isResolved = resolve;
      this.createSubscription();
    });

  createSubscription() {
    const { line, roomsSessionId, store, isResolved } = this;

    if (line === undefined) return;

    this.channel = consumer.subscriptions.create(
      {
        channel: 'RoomReactionsChannel',
        room: `${roomsSessionId}_${line.value}`,
      },
      {
        connected() {
          isResolved();
          // Called when the subscription is ready for use on the server
          log.info('Reaction channel connected');
          log.debug('Reaction channel', line);
          if (store) store.dispatch('reaction/storeLine', line);
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          if (this.channel) this.channel.unsubscribe();
        },

        received(data) {
          log.debug('Reaction received,', data);
          if (data.type === 'reaction') {
            EventBus.$emit('show-reaction', { ...data.reaction, meta: data.meta });
          } else if (data.type === 'request_dm_line') {
            if (store.state.visitor.id !== data.id) {
              const { publicReaction } = store.state.reaction;
              if (!publicReaction) return;
              // Send a message on the public channel so other subscribers can also
              // subscribe to this direct channel
              publicReaction.send({
                type: 'dm_invitation',
                sentBy: store.state.visitor.id,
                directLine: store.state.reaction.lines.find((l) => l.value.includes(store.state.visitor.id)),
              });
            }
          } else if (data.type === 'dm_invitation') {
            const { sentBy, directLine } = data;
            if (store && !store.state.admin.testing) {
              log.info('Responding to direct reaction channel invitation');
              store.dispatch('reaction/handleDMChannelInvitation', {
                sentBy,
                directLine,
                store,
              });
            }
          }
        },
      }
    );
  }
}
