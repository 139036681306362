/**
 * CalendarEventsChannel subscribes to the CalendarEventsChannel on the server to receive real-time event updates.
 * When an event is received, it checks if the event is already present in the calendar and adds it if it is new.
 */
import { metaValue } from 'helpers/dom-helper';
import consumer from './consumer';

export default class CalendarEventsChannel {
  /**
   * Initializes a new instance of CalendarEventsChannel.
   * Subscribes to the channel and binds the received method to handle incoming events.
   */
  constructor(element) {
    this.channel = consumer.subscriptions.create(
      {
        channel: 'CalendarEventsChannel',
        organization_id: this.orgid,
      },
      {
        received: this.received.bind(this),
      }
    );
  }

  /**
   * Handles received events by checking if each event is already present in the calendar.
   * Adds new events to the calendar. Logs an error if the calendar is not defined.
   *
   * @param {Object} data - Contains the array of events.
   * @param {Array} [data.events] - List of event objects to process.
   * @param {String} [data.event] - The id of the event in question to be updated.
   * @param {String} [data.icons] - The icons HTML to update on the calendar.
   */
  received({ events = null, event = null, icons = null, cid } = {}) {
    $('#calendarMask').hide();
    if (cid !== this.cid) return; // Only broadcast to the same client.

    if (Array.isArray(events)) {
      for (const event of events) {
        const existingEvent = this.calendar.getEventById(event.id);
        if (!existingEvent) {
          this.calendar.addEvent(event);
        }
      }
    }

    if (event && icons) {
      const iconsContainer = $(`div.fc-event[data-event-id="${event}"]`).find('.fc-event-icons');
      if (iconsContainer.length) {
        iconsContainer.html(icons); // Insert icons into the child div
      } else {
        // DONT ERROR.  They could have changed views which would cause this.  just ignore it.
      }
    }
  }

  /**
   * Getter for the calendar object.
   *
   * @return {Object|null} The calendar object from the global window, or null if not defined.
   */
  get calendar() {
    return window.calendar;
  }

  /**
   * The client id.
   * @returns {string|null}
   */
  get cid() {
    return metaValue('client-id');
  }

  /**
   * The current org id
   * @returns {string|null}
   */
  get orgid() {
    return metaValue('current-org-id')
  }

}
