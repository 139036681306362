export default class CommandPaletteItemElement extends HTMLElement {
  connectedCallback() {
    this.setAttribute('role', 'option');

    this.item = this.firstElementChild;
    if (!this.item) return;

    this.item.setAttribute('tabindex', '-1');
  }
}
