import ApplicationController from './application_controller';

const COMPACT_ATTR_NAME = 'data-sidebar-compact';

export default class extends ApplicationController {
  static classes = ['toggle'];

  connect() {
    if (this.isTurbolinksPreview) {
      this.renderFromCache();
    }
  }

  disconnect() {
    this.renderFromCache();
  }

  toggleNavSize() {
    this.element.classList.toggle(this.toggleClass);
    this.sidebarCache?.toggleAttribute(COMPACT_ATTR_NAME, !this.isExpanded);
  }

  // Private

  renderFromCache() {
    if (!this.sidebarCache) return;
    this.element.classList.toggle(this.toggleClass, this.sidebarCache.hasAttribute(COMPACT_ATTR_NAME));
  }

  get isExpanded() {
    return !this.element.classList.contains(this.toggleClass);
  }

  // eslint-disable-next-line class-methods-use-this
  get sidebarCache() {
    return document.getElementById('turbolinks-sidebar-cache');
  }
}
