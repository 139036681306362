import axios from 'axios';

/* eslint-disable no-underscore-dangle */
export default class StrongPassword {
  constructor(options = {}) {
    this.resultContainer = options.container;
    this.password = null;
    this.confirmation = null;
    this.results = {};
    this.score = 0;
    this.disabled = false;
  }

  validate = async (password) => {
    if (this.disabled) return true;

    this.password = password;
    this.results = await this.result();
    if (this.resultContainer) {
      const existingResults = document.getElementById('strong_password_results');
      if (existingResults) existingResults.remove();
      this.resultContainer.insertAdjacentHTML('afterend', this.resultsHtml());
    }
    return this.valid();
  }

  matches = (confirmation) => {
    if (this.disabled) return true;

    this.confirmation = confirmation;
    const existingMsg = document.getElementById('confirmation');
    if (existingMsg) existingMsg.remove();
    if (this.confirmation.length === 0) return false;

    const results = document.getElementById('results_list');
    if (!results) return false;

    const li = document.createElement('li');
    li.id = 'confirmation';
    li.classList = this.confirmation === this.password ? 'text-success' : 'text-warning';
    results.append(li);
    li.innerHTML = this.confirmation === this.password ? '<i class="bi bi-check2"></i> Passwords match' : '<i class="bi bi-x"></i> Passwords do not match';
    return this.valid();
  }

  result = async () => {
    const strengthResponse = await this.sufficientStrength(this.password);
    this.score = strengthResponse.score;

    return {
      sufficientLength: {
        valid: this.sufficientLength(this.password),
        explanation: 'Between 12-128 characters',
      },
      strength: {
        valid: strengthResponse.value,
        explanation: strengthResponse.value ? 'Strong password' : 'Your password is not strong enough. Avoid common words. Try making it longer.',
      },
    };
  }

  resultsHtml = () => {
    let html = '<div id="strong_password_results" class="mt-2"><div class="d-flex flex-row w-100 mb-2">';
    let color = this.score >= 1 ? 'success' : 'danger';
    if (color !== 'success') color = this.score < 1 && this.score >= 0.5 ? 'warning' : 'danger';
    const bar = '<div class="password-bar';
    for (let index = 0; index < 4; index += 1) {
      let styled;
      switch (index) {
        case 0:
          styled = this.score >= 0;
          break;
        case 1:
          styled = this.score >= 0.25;
          break;
        case 2:
          styled = this.score >= 0.5;
          break;
        case 3:
          styled = this.score >= 1;
          break;
        default:
          styled = false;
          break;
      }
      html += `${bar} ${styled ? `bg-${color}` : ''}"></div>`;
    }
    html += '</div><ul id="results_list" class="list-unstyled text-md">';
    Object.keys(this.results).forEach((r) => {
      const o = this.results[r];
      const icon = o.valid ? '<i class="bi bi-check2"></i>' : '<i class="bi bi-x"></i>';
      html = `${html}<li class="text-${o.valid ? 'success' : 'warning'}">${icon} <span>${o.explanation}</span></li>`;
    });
    return `${html}</ul></div>`;
  }

  sufficientLength = () => {
    const regex = /^.{12,128}/;
    return regex.test(this.password);
  }

  sufficientStrength = () => new Promise((resolve) => {
    axios.get('/api/v2/black-knight/ask', {
      params: { password: this.password },
    }).then((response) => {
      resolve(response.data);
    }).catch(() => {
      // Silently fail. It should be rare that this throws an error. Disable the plugin and
      // show everything as valid to let the server side checking handle it.
      this.disabled = true;
    });
  })

  valid = () => this.password === this.confirmation
    && this.score >= 1
    && !Object.keys(this.results).map((r) => r.valid).includes(false);
}
