import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = { object: String, highlight: Boolean };

  static classes = ['highlight'];

  targetSection = null;

  scrollIntoView(event) {
    event.preventDefault();
    const targetSectionId = event.target.href.split('#')[1];
    if (!targetSectionId) return;

    const targetSection = document.getElementById(targetSectionId);
    this.targetSection = targetSection;
    this.targetSection.classList.add('section-anchor');
    this.highlightTargetSection();
    targetSection.scrollIntoView(this.scrollProperty);
  }

  disconnect() {
    this.unregisterHighlightRemovalEvent();
  }

  // Private

  highlightTargetSection() {
    if (this.highlightValue) {
      this.targetSection.classList.add(this.targetHighlightClass);
      this.registerHighlightRemovalEvent();
    }
  }

  registerHighlightRemovalEvent() {
    // https://www.betterstimulus.com/events.html
    this.boundRemoveHighlight = this.removeHighlight.bind(this);
    document.addEventListener('click', this.boundRemoveHighlight);
  }

  unregisterHighlightRemovalEvent() {
    document.removeEventListener('click', this.boundRemoveHighlight);
  }

  removeHighlight(event) {
    if (!this.targetSection.contains(event.target) && !this.element.contains(event.target)) {
      this.targetSection.classList.remove(this.targetHighlightClass);
    }
  }

  get scrollProperty() {
    let newObject = {};
    if (this.hasObjectValue) {
      newObject = JSON.parse(this.objectValue);
    }

    return {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
      ...newObject,
    };
  }

  get targetHighlightClass() {
    return this.hasHighlightClass ? this.highlightClass : 'bg-info';
  }
}
