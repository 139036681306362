import { isEmpty } from '../../helpers/metal';

export default class CommandPaletteElement extends HTMLElement {
  connectedCallback() {
    this.addScope = this.addScope.bind(this);
    this.removeScope = this.removeScope.bind(this);

    this.addEventListener('command-palette:scopeUpdated', this.addScope);
    this.addEventListener('command-palette:scopeRemoved', this.removeScope);
  }

  disconnectedCallback() {
    this.removeEventListener('command-palette:scopeUpdated', this.addScope);
    this.removeEventListener('command-palette:scopeRemoved', this.removeScope);
  }

  addScope(event) {
    const object = JSON.parse(event.detail);
    if (isEmpty(object)) return;

    const { id, title } = object;
    if (!(this.container instanceof HTMLElement)) return;

    const template = `
      <div data-group-id="${id}" class="d-inline-flex align-items-center">
        <span class="d-block text-truncate" style="max-width: 100px;">${title}</span>
        <span>&nbsp;/&nbsp;&nbsp;</span>
      </div>
    `;

    this.container.innerHTML = template;
  }

  removeScope() {
    if (!(this.container instanceof HTMLElement)) return;

    this.container.innerHTML = '';
  }

  get container() {
    return this.querySelector('[data-scopes]');
  }
}
