(function () {
  const Turbolinks = require('turbolinks');
  let scrollPosition;

  document.addEventListener('turbolinks:load', () => {
    if (scrollPosition) {
      window.scrollTo(...scrollPosition);
      scrollPosition = null;
    }
  }, false);

  window.reloadTurbolinks = function () {
    scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location, { action: 'replace' });
  };
})();
